/* import __COLOCATED_TEMPLATE__ from './source.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type ImportSource from 'embercom/models/import-service/import-source';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  source: ImportSource;
}

export default class Source extends Component<Args> {
  @service declare intl: IntlService;
  @service appService: any;
  @service declare permissionsService: $TSFixMe;
  @tracked showRemoveSourceModal = false;

  get sourceOptions() {
    return [
      {
        text: this.intl.t('knowledge-hub.filterable-list.sources.actions.resync'),
        icon: 'sync',
        onSelectItem: async () => {
          try {
            await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
            this.args.source.triggerResync();
          } catch (error) {
            return;
          }
        },
      },
      {
        text: this.intl.t('knowledge-hub.filterable-list.sources.actions.remove'),
        icon: 'delete',
        onSelectItem: async () => {
          try {
            await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
            this.setRemoveSourceModal(true);
          } catch (error) {
            return;
          }
        },
      },
      {
        text: this.intl.t('knowledge-hub.filterable-list.sources.actions.import-source'),
        icon: 'upgrade',
        onSelectItem: async () => {
          try {
            await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
            this.args.source.triggerImport();
          } catch (error) {
            return;
          }
        },
      },
    ];
  }

  get failureBannerText() {
    if (this.args.source.isImport) {
      return this.intl.t('knowledge-hub.filterable-list.sources.failed-import-banner');
    }

    return this.intl.t('knowledge-hub.filterable-list.sources.failed-sync-banner');
  }

  @action setRemoveSourceModal(updatedValue = false) {
    this.showRemoveSourceModal = updatedValue;
  }

  @action removeSource() {
    this.args.source.remove();
    this.setRemoveSourceModal(false);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Source': typeof Source;
    'knowledge-hub/filterable-list/source': typeof Source;
  }
}
