/* import __COLOCATED_TEMPLATE__ from './selection-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import Folder from 'embercom/models/content-service/folder';
import { type TreeItem } from 'embercom/objects/tree-list';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  item: TreeItem<Folder | undefined>;
  routeAfterDelete: string;
}

export default class SelectionListItem extends Component<Args> {
  @service intl!: IntlService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get folder(): Folder | undefined {
    if (this.args.item.dataObject instanceof Folder) {
      return this.args.item.dataObject;
    }
    return undefined;
  }

  get tree() {
    return this.args.item.tree;
  }

  get canSelectItem() {
    return this.tree.hooks.canSelectItem(this.args.item);
  }

  get idForTest() {
    return this.folder?.id ?? 'undefined';
  }

  get showFolderTitle() {
    return this.folder || this.args.item.dataObject === undefined;
  }

  get folderTitle() {
    if (this.args.item.dataObject === undefined) {
      return this.intl.t('knowledge-hub.folders.heading');
    }
    return this.folder?.title ?? '';
  }

  @action onClick(event: MouseEvent) {
    if (this.canSelectItem) {
      this.tree.hooks.didSelectItem(this.args.item);
      event.stopPropagation();
    } else {
      event.preventDefault();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Folders::Tree::SelectionListItem': typeof SelectionListItem;
    'knowledge-hub/folders/tree/selection-list-item': typeof SelectionListItem;
  }
}
