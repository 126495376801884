/* import __COLOCATED_TEMPLATE__ from './loading-skeleton.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { DRAWER_EDITOR_SHOW_SIDE_PANEL } from 'embercom/lib/knowledge-hub/constants';
import storage from 'embercom/vendor/intercom/storage';

interface Signature {
  Element: HTMLDivElement;
}

export default class LoadingSkeleton extends Component<Signature> {
  showSidePanel = storage.get(DRAWER_EDITOR_SHOW_SIDE_PANEL) ?? true;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::LoadingSkeleton': typeof LoadingSkeleton;
    'knowledge-hub/loading-skeleton': typeof LoadingSkeleton;
  }
}
