/* import __COLOCATED_TEMPLATE__ from './item-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

interface TreeItem {
  id: string;
  title: string;
  type: 'file' | 'folder';
  level: number;
  parent_id: string | null;
  children_ids: string[];
}

interface Args {
  items?: TreeItem[];
  selectedItems?: any[];
  onItemsSelected?: (items: TreeItem[]) => void;
  isLoading?: boolean;
  connectionType: string;
  allowedFileTypes: string[];
}

interface ItemSelection extends TreeItem {
  selected: boolean;
  expanded: boolean;
}

export default class IntegrationItemSelection extends Component<Args> {
  @tracked selectedItems: Set<string> = new Set();
  @tracked expandedFolders: Set<string> = new Set();
  @tracked itemsToShow = 20;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    // set the selected items if reconfiguring
    if (args.selectedItems) {
      this.selectedItems = new Set(args.selectedItems.map((item) => item.id));
    }
  }

  // update the selected items if reconfiguring
  willUpdate(args: Args) {
    if (args.selectedItems && args.selectedItems !== this.args.selectedItems) {
      this.selectedItems = new Set(args.selectedItems.map((item) => item.id));
    }
  }

  get items(): ItemSelection[] {
    let allItems = this.args.items || [];
    // Create a map of parent_id to children for easier lookup
    let itemsByParent = new Map<string | null, TreeItem[]>();
    allItems.forEach((item) => {
      let parentId = item.parent_id;
      if (!itemsByParent.has(parentId)) {
        itemsByParent.set(parentId, []);
      }
      itemsByParent.get(parentId)?.push(item);
    });

    // Recursive function to build ordered list
    let buildOrderedList = (parentId: string | null): ItemSelection[] => {
      let items = itemsByParent.get(parentId) || [];
      return items.reduce((acc: ItemSelection[], item) => {
        let itemWithState = {
          ...item,
          selected: this.selectedItems.has(item.id),
          expanded: this.expandedFolders.has(item.id),
        };
        acc.push(itemWithState);
        // If it's an expanded folder, recursively add its children
        if (item.type === 'folder' && this.expandedFolders.has(item.id)) {
          acc.push(...buildOrderedList(item.id));
        }
        return acc;
      }, []);
    };

    // Start with root level items (parent_id is null)
    return buildOrderedList(null);
  }

  get visibleItems(): ItemSelection[] {
    return this.items.slice(0, this.itemsToShow);
  }

  get hasMoreItems(): boolean {
    return this.itemsToShow < this.items.length;
  }

  shouldShowItem(item: TreeItem): boolean {
    if (item.parent_id === null) {
      return true;
    } else {
      return this.expandedFolders.has(item.parent_id);
    }
  }

  get isLoading() {
    return this.args.isLoading;
  }
  get allowedFileTypes() {
    return this.args.allowedFileTypes.join(', ');
  }

  getIndentStyle(item: ItemSelection) {
    return sanitizeHtml(`margin-left: ${item.level * 16}px`);
  }

  @action
  toggleExpand(item: ItemSelection) {
    if (item.type !== 'folder') {
      return;
    }
    if (this.expandedFolders.has(item.id)) {
      this.expandedFolders.delete(item.id);
    } else {
      this.expandedFolders.add(item.id);
    }
    this.expandedFolders = new Set(this.expandedFolders);
  }

  @action
  toggleSelection(item: ItemSelection) {
    if (this.selectedItems.has(item.id)) {
      this.selectedItems.delete(item.id);
      if (item.type === 'folder') {
        this.unselectChildren(item);
      }
    } else {
      this.selectedItems.add(item.id);
      if (item.type === 'folder') {
        this.selectChildren(item);
      }
    }
    this.selectedItems = new Set(this.selectedItems);
    // Convert selected IDs to full items before sending to parent
    let selectedFullItems = this.getSelectedFullItems();
    this.args.onItemsSelected?.(selectedFullItems);
  }

  private getSelectedFullItems(): TreeItem[] {
    let allItems = this.args.items || [];
    return Array.from(this.selectedItems).map((id) => {
      let item = allItems.find((item) => item.id === id);
      if (!item) {
        throw new Error(`Selected item with ID ${id} not found in items list`);
      }
      return {
        id: item.id,
        title: item.title,
        type: item.type,
        level: item.level,
        parent_id: item.parent_id,
        children_ids: item.children_ids,
      };
    });
  }

  private selectChildren(folder: TreeItem) {
    let allItems = this.args.items || [];
    folder.children_ids.forEach((childId) => {
      let child = allItems.find((item) => item.id === childId);
      if (child) {
        this.selectedItems.add(child.id);
        if (child.type === 'folder') {
          this.selectChildren(child);
        }
      }
    });
  }

  private unselectChildren(folder: TreeItem) {
    let allItems = this.args.items || [];
    folder.children_ids.forEach((childId) => {
      let child = allItems.find((item) => item.id === childId);
      if (child) {
        this.selectedItems.delete(child.id);
        if (child.type === 'folder') {
          this.unselectChildren(child);
        }
      }
    });
  }

  @action
  selectAll() {
    this.args.items?.forEach((item) => {
      this.selectedItems.add(item.id);
    });
    this.selectedItems = new Set(this.selectedItems);
    let selectedFullItems = this.getSelectedFullItems();
    this.args.onItemsSelected?.(selectedFullItems);
  }

  @action
  unselectAll() {
    this.selectedItems.clear();
    this.selectedItems = new Set(this.selectedItems);
    this.args.onItemsSelected?.([]);
  }

  @action
  loadMore() {
    this.itemsToShow += 20; // Load 20 more items
  }

  get selectAllState() {
    let allItems = this.args.items || [];
    let allSelected =
      allItems.length > 0 && allItems.every((item) => this.selectedItems.has(item.id));
    return {
      checked: allSelected,
      text: allSelected
        ? 'knowledge-hub.sync-modal.unselect-all'
        : 'knowledge-hub.sync-modal.select-all',
    };
  }

  @action
  toggleSelectAll() {
    if (this.selectAllState.checked) {
      this.unselectAll();
    } else {
      this.selectAll();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::ItemSelector': typeof IntegrationItemSelection;
    'knowledge-hub/setup-modal/sync-modal/item-selector': typeof IntegrationItemSelection;
  }
}
