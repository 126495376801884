/* import __COLOCATED_TEMPLATE__ from './progress-bar.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import type ImportSource from 'embercom/models/import-service/import-source';
import {
  IMPORT_SOURCE_IN_PROGRESS_STATUSES,
  IMPORT_SOURCE_STATUSES,
} from 'embercom/lib/knowledge-hub/constants';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';

interface Args {
  source: ImportSource;
  isFolderView?: boolean;
}

export default class ProgressBar extends Component<Args> {
  @service declare intl: IntlService;

  get source() {
    return this.args.source;
  }

  get totalStepCount() {
    return this.steps.length;
  }

  get currentStep() {
    return this.steps.indexOf(this.source?.status) + 1;
  }

  get steps() {
    let steps = IMPORT_SOURCE_IN_PROGRESS_STATUSES;
    if (this.source.isSync) {
      steps = steps.filter((step) => step !== IMPORT_SOURCE_STATUSES.import);
    }

    return steps;
  }

  get inProgressTitle() {
    if (this.source.isImport) {
      return this.intl.t('knowledge-hub.filterable-list.sources.import-in-progress-banner');
    }

    return this.intl.t('knowledge-hub.filterable-list.sources.in-progress-banner');
  }

  get folderInProgressTitle() {
    if (this.source.isImport) {
      return this.intl.t('knowledge-hub.folders.sync-card.description-import', {
        source: this.source.sourceName,
      });
    }

    return this.intl.t('knowledge-hub.folders.sync-card.description-sync', {
      source: this.source.sourceName,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::ProgressBar': typeof ProgressBar;
  }
}
