/* import __COLOCATED_TEMPLATE__ from './bulk-actions-checkbox.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type ContentFragment from 'embercom/models/content-service/content-fragment';
import ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type { KnowledgeHubTableRow } from 'embercom/models/content-service/knowledge-hub-content-wrapper';

interface Args {
  tableRow: KnowledgeHubTableRow;
  isSibling?: boolean;
  isBulkSelection: boolean;
  selectedFragments: Set<ContentFragment>;
  setSelectedFragments: (wrappers: Set<ContentFragment>) => void;
  unselectedFragments: Set<ContentFragment>;
  setUnselectedFragments: (wrappers: Set<ContentFragment>) => void;
  showSiblings: () => void;
  activeHoverRowId: string;
}

export default class BulkActionsCheckbox extends Component<Args> {
  @service store: any;
  @service declare appService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.expandIfOneChildSelected();
  }

  private expandIfOneChildSelected() {
    let tableRow = this.args.tableRow;
    if (tableRow instanceof ContentWrapper) {
      if (
        this.args.showSiblings &&
        tableRow.contents.any((content) => this.isRowSelected(content))
      ) {
        this.args.showSiblings();
      }
    }
  }

  get checked(): boolean {
    return (
      this.isRowSelected(this.args.tableRow) ||
      (this.args.isBulkSelection && !this.isRowUnselected(this.args.tableRow))
    );
  }

  get showCheckbox(): boolean {
    return (
      this.args.activeHoverRowId === this.args.tableRow.id || this.args.selectedFragments.size > 0
    );
  }

  private isRowSelected(tableRow: KnowledgeHubTableRow): boolean {
    return tableRow.allContentIncludedIn(this.args.selectedFragments);
  }

  private isRowUnselected(tableRow: KnowledgeHubTableRow) {
    return tableRow.anyContentIncludedIn(this.args.unselectedFragments);
  }

  @action
  toggleSelection(): void {
    let tableRow = this.args.tableRow;
    let selection: Array<ContentFragment> = [];
    if (tableRow instanceof ContentWrapper) {
      selection = tableRow.contents.toArray();
    } else {
      selection = [tableRow];
    }
    let selected = !this.checked;
    this.setSelection(selection, selected);
    if (this.args.showSiblings && selected) {
      this.args.showSiblings();
    }
  }

  private setSelection(contentFragments: ContentFragment[], selected: boolean): void {
    let selectedFragments = this.args.selectedFragments;
    let unselectedFragments = this.args.unselectedFragments;
    contentFragments.forEach((contentFragment) => {
      if (selected) {
        selectedFragments.add(contentFragment);
        this.args.isBulkSelection ? unselectedFragments.delete(contentFragment) : null;
      } else {
        selectedFragments.delete(contentFragment);
        this.args.isBulkSelection ? unselectedFragments.add(contentFragment) : null;
      }
    });
    this.args.setSelectedFragments(selectedFragments);
    this.args.setUnselectedFragments(unselectedFragments);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::BulkActionsCheckbox': typeof BulkActionsCheckbox;
    'knowledge-hub/filterable-list/table/cells/bulk-actions-checkbox': typeof BulkActionsCheckbox;
  }
}
