/* import __COLOCATED_TEMPLATE__ from './written-by.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';
import ContentFragment from 'embercom/models/content-service/content-fragment';
import type Store from '@ember-data/store';
import { EntityType } from 'embercom/models/data/entity-types';

interface Args {
  tableRow: KnowledgeHubContentWrapper | ContentFragment;
}

export default class WrittenBy extends Component<Args> {
  @service declare helpCenterService: $TSFixMe;
  @service declare store: Store;

  get isArticle(): boolean {
    return this.args.tableRow.contentType === EntityType.ArticleContent;
  }

  get adminIds(): string[] {
    if (!this.isArticle) {
      return [];
    }

    if (this.args.tableRow instanceof KnowledgeHubContentWrapper) {
      return [
        ...new Set(this.args.tableRow.contents.map((content) => `${content.senderId}`).compact()),
      ];
    }
    if (this.args.tableRow instanceof ContentFragment) {
      return [`${this.args.tableRow.senderId}`].compact();
    }

    return [];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::WrittenBy': typeof WrittenBy;
    'knowledge-hub/filterable-list/table/cells/written-by': typeof WrittenBy;
  }
}
