/* import __COLOCATED_TEMPLATE__ from './grouped-table.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { assert } from '@ember/debug';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { type TableColumn } from 'embercom/components/knowledge-hub/filterable-list/list';
import type KnowledgeHubContentWrapper from 'embercom/models/content-service/knowledge-hub-content-wrapper';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { inject as service } from '@ember/service';
import type ContentFragment from 'embercom/models/content-service/content-fragment';
import { type SortDirection } from 'embercom/lib/knowledge-hub/constants';
import type IntlService from 'ember-intl/services/intl';

interface SortState {
  valuePath?: string;
  direction?: SortDirection;
}

export interface Signature {
  Element: HTMLElement;

  Args: {
    canLoadMore?: boolean;
    class: string;
    columns?: TableColumn[];
    data: KnowledgeHubContentWrapper[];
    isLoading?: boolean;
    isLoadingMore?: boolean;
    loadingRowCount?: number;
    onLoadMore?: () => void;
    onSort?: (valuePath: string) => void;
    onBulkSelect?: () => void;
    selectedContent?: string[];
    showAllSiblings?: boolean;
    sortState?: SortState;
    removeContentWrapper?: (contentWrapperId: string) => void;
    isBulkSelection: boolean;
    selectedFragments?: Set<ContentFragment>;
    selectedCount: number;
    totalCount: number;
    setIsBulkSelection: (isBulkSelection: boolean) => void;
  };

  Blocks: {
    default: [{ rows: any }];
  };
}

export default class GroupedTable extends Component<Signature> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intl: IntlService;

  @tracked sortState = this.args.sortState || {};
  @tracked loadingRowCount = 8;
  @tracked isHovering = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    assert(
      'You must provide an `onSort` action if any of your columns are sortable.',
      !(this.atLeastOneColumnIsSortable && typeof this.onSort !== 'function'),
    );
    assert(
      'You must provide an `onLoadMore` action if `canLoadMore` is set.',
      !this.canLoadMore || typeof this.onLoadMore === 'function',
    );
  }

  get columns(): TableColumn[] {
    if (!this.args.columns) {
      return [];
    }

    return this.args.columns;
  }

  get isCheckboxHidden() {
    return !(
      this.isHovering ||
      (this.args.selectedFragments && this.args.selectedFragments.size > 0) ||
      this.bulkSelectChecked
    );
  }

  get checkboxIsDashed() {
    return this.args.selectedCount !== 0 && this.args.selectedCount !== this.args.totalCount;
  }

  get processedColumns(): TableColumn[] {
    return this.columns.map((column) => {
      column.isVisible = column.isVisible !== false;
      column.label = column.labelIntlKey ? this.intl.t(column.labelIntlKey) : '';
      column.tooltip = column.tooltipIntlKey ? this.intl.t(column.tooltipIntlKey) : '';
      return column;
    });
  }

  get atLeastOneColumnIsSortable() {
    return this.columns.some((column) => column.isSortable);
  }

  get atLeastOneColumnIsSelecteable() {
    return this.columns.some((column) => column.type === 'checkbox');
  }

  get columnsHash() {
    let seenValuePaths = new Set();
    return this.processedColumns.reduce((acc, column) => {
      assert(
        `Each column must have a non-empty valuePath property of type String. Assertion failed for ${column.valuePath}`,
        typeof column.valuePath === 'string' && column.valuePath.length > 0,
      );
      assert(
        `Each column must have a unique valuePath property. \`${column.valuePath}\` was used at least twice.`,
        !seenValuePaths.has(column.valuePath),
      );
      seenValuePaths.add(column.valuePath);
      return Object.assign(acc, {
        [column.valuePath]: column,
      });
    }, {});
  }

  get canLoadMore() {
    if (this.args.canLoadMore) {
      return this.args.canLoadMore;
    }

    return false;
  }

  get isLoading() {
    if (this.args.isLoading) {
      return this.args.isLoading;
    }

    return false;
  }

  get hasSelectedAllItems() {
    if (this.args.selectedFragments) {
      return (
        this.args.selectedFragments.size > 0 &&
        this.args.selectedFragments.size === this.args.totalCount
      );
    }
    return false;
  }

  get bulkSelectChecked(): boolean {
    if (this.isLoading) {
      return false;
    }

    return (
      this.hasSelectedAllItems ||
      (this.args.isBulkSelection && this.args.selectedCount === this.args.totalCount)
    );
  }

  @action
  onSort(valuePath: string) {
    if (this.args.onSort) {
      this.args.onSort(valuePath);

      if (this.args.sortState) {
        this.sortState = this.args.sortState;
      }
    }
  }

  @action
  onLoadMore() {
    if (this.args.onLoadMore) {
      this.args.onLoadMore();
    }
  }

  @action
  _onColumnHeaderClick(valuePath: string, type: string) {
    if (!this.isLoading) {
      if (typeof this.onSort === 'function' && type === 'sort') {
        this.onSort(valuePath);
        this.scrollToContainerTop();
      }

      if (type === 'select') {
        if (this.hasSelectedAllItems && !this.args.isBulkSelection) {
          this.args.setIsBulkSelection(!this.args.isBulkSelection);
        }

        if (this.args.onBulkSelect) {
          this.args.onBulkSelect();
        }
      }
    }
  }

  scrollToContainerTop() {
    let verticalScrollContainerElement = document.querySelector('[data-table-container]');
    if (verticalScrollContainerElement) {
      let { y: verticalScrollContainerYPosition } =
        verticalScrollContainerElement.getBoundingClientRect();
      if (verticalScrollContainerYPosition < 0) {
        verticalScrollContainerElement.scrollIntoView();
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::GroupedTable': typeof GroupedTable;
    'knowledge-hub/filterable-list/table/grouped-table': typeof GroupedTable;
  }
}
