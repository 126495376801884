/* import __COLOCATED_TEMPLATE__ from './content-review.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type KnowledgeHubEditorConfig } from 'embercom/objects/knowledge-hub/knowledge-hub-editor-config';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import {
  CAN_MANAGE_KNOWLEDGE_BASE_CONTENT,
  DRAWER_EDITOR_SHOW_SIDE_PANEL,
} from 'embercom/lib/knowledge-hub/constants';
import storage from 'embercom/vendor/intercom/storage';
import type IntlService from 'ember-intl/services/intl';
import {
  ContentAction,
  type LocalizedKnowledgeContent,
} from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';
import type ContentReviewRequest from 'embercom/models/content-service/content-review-request';
import { ReviewType } from 'embercom/models/content-service/content-review-request';
import { tracked } from '@glimmer/tracking';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
import { type Block } from '@intercom/interblocks.ts';
import type ContentSuggestionsService from 'embercom/services/content-suggestions-service';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import RouteRegexes from 'embercom/lib/route-regexes';
import type RouterService from '@ember/routing/router-service';
import type ArticleContent from 'embercom/models/articles/article-content';
import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import { post } from 'embercom/lib/ajax';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type SuggestionService from 'embercom/services/suggestion-service';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import type Store from '@ember-data/store';

export type ReviewableContent = ArticleContent | ContentSnippet;

interface Args {
  activeContent: LocalizedKnowledgeContent & Model;
  onClose?: () => void;
}

export default class ContentReview extends Component<Args> {
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare permissionsService: $TSFixMe;
  @service declare contentSuggestionsService: ContentSuggestionsService;
  @service declare router: RouterService;
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare suggestionService: SuggestionService;
  @service declare store: Store;

  @trackedInLocalStorage({
    keyName: DRAWER_EDITOR_SHOW_SIDE_PANEL,
  })
  showSidePanel = true;

  @tracked updatedSuggestionBlocks: Array<Block> = [];

  @tracked declare activeContent: LocalizedKnowledgeContent & Model;

  constructor(owner: any, args: Args) {
    super(owner, args);

    this.showSidePanel = storage.get(DRAWER_EDITOR_SHOW_SIDE_PANEL) ?? true;

    this.expandToFullScreenIfNeeded();

    this.activeContent = this.args.activeContent;
  }

  get editorConfig(): KnowledgeHubEditorConfig | undefined {
    return this.args.activeContent && this.args.activeContent.editorConfig
      ? this.args.activeContent.editorConfig
      : undefined;
  }

  get reviewReason() {
    return this.contentReviewRequest().get('reviewReason');
  }

  get reviewRequestCreatedAt() {
    return this.intl.t('fin-ai-agent.knowledge.content-suggestions.created', {
      date: this.intl.formatRelative(new Date(this.contentReviewRequest().get('createdAt'))),
    });
  }

  get isEditReviewType() {
    return this.contentReviewRequest().get('reviewType') === ReviewType.EDIT_CONTENT;
  }

  get reviewRequest() {
    return this.contentReviewRequest();
  }

  get updatingContentReviewRequest() {
    return (
      taskFor(this.performUpdateContentReviewRequest).isRunning ||
      taskFor(this.performSaveAction).isRunning
    );
  }

  get isOnAiInsightsPage() {
    return RouteRegexes.automation.insights.test(this.router.currentRouteName);
  }

  private contentReviewRequest(): ContentReviewRequest & Model {
    return (this.args.activeContent as ReviewableContent).contentReviewRequest;
  }

  @action
  toggleSidePanel(): void {
    this.showSidePanel = !this.showSidePanel;

    this.expandToFullScreenIfNeeded();

    this.knowledgeHubEditorService.trackAnalyticsEvent(
      `${this.showSidePanel ? 'show' : 'hide'}_details_side_panel`,
    );
  }

  @action
  async approveVersion() {
    await taskFor(this.performSaveAction).perform('approve_and_publish');
    this.removeContentFromAiInsightsList();
  }

  @action
  async rejectVersion() {
    await taskFor(this.performUpdateContentReviewRequest).perform(Status.Rejected);
    this.removeContentFromAiInsightsList();
    await this.suggestionService.fetchTopicsSummary();
  }

  @action
  updateSuggestionBlocks(blocks: Array<Block>) {
    this.updatedSuggestionBlocks = blocks;
  }

  @action
  async saveAsDraft() {
    await taskFor(this.performSaveAction).perform('save_draft');
    await this.reloadActiveContent();

    let reviewRequests =
      this.contentReviewRequest().get('status') === Status.Pending
        ? [this.contentReviewRequest()]
        : [];

    this.suggestionService.createBlockGroups(this.activeContent, reviewRequests);
  }

  get rejectedContentReviewRequestIds() {
    return this.suggestionService.blockGroups
      .filter((blockGroup) => blockGroup.reviewRequest?.get('status') === Status.Rejected)
      .map((blockGroup) => blockGroup.reviewRequest?.get('id'));
  }

  get approvedContentReviewRequestIds() {
    return this.suggestionService.blockGroups
      .filter((blockGroup) => blockGroup.reviewRequest?.get('status') === Status.Approved)
      .map((blockGroup) => blockGroup.reviewRequest?.get('id'));
  }

  @dropTask
  *performSaveAction(reviewAction: 'save_draft' | 'approve_and_publish') {
    try {
      yield this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    let message = reviewAction === 'save_draft' ? 'draft' : 'publish';
    try {
      let payload = {
        app_id: this.appService.app.id,
        entity_id: this.args.activeContent.entityId,
        entity_type: this.args.activeContent.entityType,
        rejected_content_review_request_ids: Array.from(this.rejectedContentReviewRequestIds),
        approved_content_review_request_ids: Array.from(this.approvedContentReviewRequestIds),
        json_blocks: this.suggestionService.editedJsonBlocks,
        review_action: reviewAction,
      };
      yield post('/ember/content_service/content_review_requests/handle_suggestion', payload);
      this.notificationsService.notifyConfirmation(
        this.intl.t(`knowledge-hub.content-editor.review.handle-suggestion.success.${message}`),
      );

      // We need to update indices for both save_as_draft and approve if needed so we can keep track of where the suggestion was made
      this.updateSuggestionIndicesIfNeeded();

      if (reviewAction === 'approve_and_publish') {
        yield this.reloadSuggestionsListAndCloseDrawer();
        this.args.onClose?.();
      }
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t(`knowledge-hub.content-editor.review.handle-suggestion.error.${message}`),
      );
    }
    yield this.suggestionService.fetchTopicsSummary(true);
  }

  private async updateSuggestionIndicesIfNeeded() {
    let blockgroups = this.suggestionService.blockGroups;
    // Start from index 1 because if the suggestion is the first one, there are no blocks above it
    for (let i = 1; i < blockgroups.length; i++) {
      let blockGroup = blockgroups[i];
      let suggestion = blockGroup.reviewRequest?.get('suggestion');

      if (blockGroup.isEdited && suggestion) {
        // We need the total count of blocks above this suggestion
        let previousBlocksCount = blockgroups
          .slice(0, i)
          .reduce((sum, group) => sum + (group.blocks.length || 0), 0);

        let [startIndex, endIndex] = suggestion.indices;
        let blocksCountDifference = previousBlocksCount - startIndex;

        // if there is a difference in the count of blocks between the previous blocks and the start index of this suggestion,
        // then we need to update the indices of the suggestion so we don't lose track of where the suggestion is in the content
        if (blocksCountDifference !== 0) {
          suggestion.indices = [
            Math.max(startIndex + blocksCountDifference, 0),
            Math.max(endIndex + blocksCountDifference, 0),
          ];
          blockGroup.reviewRequest?.set('suggestion', suggestion);
          let reviewRequest = blockGroup.reviewRequest as ContentReviewRequest;
          // Need to pull record from store to be able to call save
          let record: ContentReviewRequest = await this.store.peekRecord(
            'content-service/content-review-request',
            reviewRequest.get('id'),
          );
          await record.save();
        }
      }
    }
  }

  @dropTask
  *performUpdateContentReviewRequest(status: Status) {
    yield this.updateContentReviewRequest(status);
  }

  private async updateContentReviewRequest(status: Status) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }
    let contentReviewRequest = await this.asyncContentReviewRequest();
    let message = status === Status.Approved ? 'approve' : 'reject';

    if (contentReviewRequest) {
      contentReviewRequest.status = status;
      if (status === Status.Approved && this.updatedSuggestionBlocks.length > 0) {
        contentReviewRequest.updatedSuggestionBlocks = this.updatedSuggestionBlocks;
      }
      try {
        await contentReviewRequest.save();
        this.notificationsService.notifyConfirmation(
          this.intl.t(`knowledge-hub.suggestion-review.${message}`),
        );
      } catch (e) {
        this.notifyErrorAndCloseDrawer(
          this.intl.t(`knowledge-hub.suggestion-review.error.${message}`),
          false,
        );
      }
      this.reloadSuggestionsListAndCloseDrawer();
    } else {
      this.notifyErrorAndCloseDrawer();
    }
    this.args.onClose?.();
  }

  private removeContentFromAiInsightsList() {
    if (this.isOnAiInsightsPage) {
      this.contentSuggestionsService.removeSuggestionFromList({
        entity_id: this.args.activeContent.entityId,
        entity_type: this.args.activeContent.entityType,
      });
    }
  }

  private async reloadSuggestionsListAndCloseDrawer() {
    // Let's give ES some time to update
    await timeout(ENV.APP._1000MS);
    if (!this.isOnAiInsightsPage) {
      this.contentSuggestionsService.listApi.resetFiltersForSearch();
      await this.contentSuggestionsService.listApi.loadPage();
    }
    await this.knowledgeHubDrawerEditorService.closeDrawer();
  }

  private async asyncContentReviewRequest() {
    let content = this.args.activeContent;
    return (content as ReviewableContent).contentReviewRequest;
  }

  private async notifyErrorAndCloseDrawer(
    message = this.intl.t('knowledge-hub.suggestion-review.not-found'),
    closeDrawer = true,
  ) {
    this.notificationsService.notifyError(message);
    if (closeDrawer) {
      await this.knowledgeHubDrawerEditorService.closeDrawer();
    }
  }

  private async reloadActiveContent() {
    this.activeContent = await this.knowledgeHubService.findContent({
      contentId: this.activeContent.entityId,
      contentType: this.activeContent.entityType,
      findRecordParams: { reload: true },
    });
    await this.activeContent.loadRelatedContent(ContentAction.EDIT);
    await this.contentReviewRequest().reload();
  }

  private expandToFullScreenIfNeeded() {
    if (this.showSidePanel && !this.knowledgeHubDrawerEditorService.isFullScreen) {
      this.knowledgeHubDrawerEditorService.toggleFullScreen();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ContentReview': typeof ContentReview;
    'knowledge-hub/content-editor/content-review': typeof ContentReview;
  }
}
