/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';
import type ArticleContent from 'embercom/models/articles/article-content';

export interface Args {
  activeContent: ArticleContent;
  showSidePanel: boolean;
  cancelEditActiveContent: () => void;
  saveActiveContent: () => void;
  publishActiveContent: () => Promise<void>;
  toggleSidePanel: () => void;
}

interface Signature {
  Args: Args;
}

const Header = templateOnlyComponent<Signature>();
export default Header;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::Edit::Header': typeof Header;
    'knowledge-hub/content-editor/article-content/edit/header': typeof Header;
  }
}
