/* import __COLOCATED_TEMPLATE__ from './list-row.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';
import { type TableColumn } from './list';
import type ContentFragment from 'embercom/models/content-service/content-fragment';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    columns: TableColumn[];
    row: any;
    isBulkSelection: boolean;
    selectedFragments: Set<ContentFragment>;
    setSelectedFragments: (wrappers: Set<ContentFragment>) => void;
    unselectedFragments: Set<ContentFragment>;
    setUnselectedFragments: (wrappers: Set<ContentFragment>) => void;
  };
}

const ListRow = templateOnlyComponent<Signature>();
export default ListRow;
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::ListRow': typeof ListRow;
    'knowledge-hub/filterable-list/list-row': typeof ListRow;
  }
}
