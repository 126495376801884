/* import __COLOCATED_TEMPLATE__ from './plan.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { INCLUDED_TIERS_FROM_PRICING_ENDPOINT } from 'embercom/lib/billing';
import { keepLatestTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type Product from 'embercom/models/product';
import type Plan from 'embercom/models/plan';
import type IntershopService from 'embercom/services/intershop-service';

interface Args {
  product: Product;
  plan: Plan;
}

export default class PlanComponent extends Component<Args> {
  @service declare customerService: any;
  @service declare intershopService: IntershopService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.getPrices).perform();
  }

  get peopleReachedPlanId(): number | undefined {
    return this.intershopService.peopleReachedPlanId(this.args.plan.idAsNumber);
  }

  pricingModelIdentifier() {
    return this.customerService.currentPricingModelIdentifier;
  }

  @keepLatestTask *getPrices() {
    let planIds = [this.args.plan.idAsNumber, this.peopleReachedPlanId].compact();
    let pricesNotLoaded = planIds.filter(
      (id) => !this.customerService.isPriceLoaded([id], this.pricingModelIdentifier()),
    );
    if (pricesNotLoaded.length) {
      yield this.customerService.bulkLoadPrices(
        pricesNotLoaded.map((id) => ({
          planIds: [id],
          includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
          source: 'intershop-product-plan-route',
        })),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::CorePlans::Plan': typeof PlanComponent;
    'intershop/products/core-plans/plan': typeof PlanComponent;
  }
}
