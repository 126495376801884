/* import __COLOCATED_TEMPLATE__ from './translation-confirmation-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  intlPrefix: string;
  onConfirm: () => void;
  onClose: () => void;
}

interface Signature {
  Args: Args;
}

const VisibilitySectionTranslationConfirmationModal = templateOnlyComponent<Signature>();
export default VisibilitySectionTranslationConfirmationModal;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::TranslationConfirmationModal': typeof VisibilitySectionTranslationConfirmationModal;
  }
}
