/* import __COLOCATED_TEMPLATE__ from './content-ingestion.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { Status } from 'embercom/models/data/content-service/ai/ingestion-job-statuses';
import type ContentImportService from 'embercom/services/content-import-service';

interface Args {}

export default class ContentIngestion extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare contentImportService: ContentImportService;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.contentImportService.subscribeToContentIngestionJobStatusUpdates();
  }

  get app() {
    return this.appService.app;
  }

  get showBanner(): boolean {
    return this.contentImportService.finIngestionJobStatusBanner?.display ?? false;
  }

  get workspaceLevelIngestionInProgress(): boolean {
    return (
      this.showBanner &&
      (this.contentImportService.finIngestionJobStatusBanner?.status === Status.InProgress ||
        this.contentImportService.finIngestionJobStatusBanner?.status === Status.Pending ||
        this.contentImportService.finIngestionJobStatusBanner?.status === Status.SentToAnswerBot)
    );
  }

  get workspaceLevelIngestionCompleted(): boolean {
    return (
      this.showBanner &&
      this.contentImportService.finIngestionJobStatusBanner?.status === Status.Completed
    );
  }

  @action hideWorkspaceLevelIngestionBanner(): void {
    this.contentImportService.hideFinIngestionJobStatusBanner();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Banners::ContentIngestion': typeof ContentIngestion;
  }
}
