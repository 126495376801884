/* import __COLOCATED_TEMPLATE__ from './help-center-status.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { type TableColumn } from '../../list';
import { inject as service } from '@ember/service';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    column: TableColumn;
  };
}

export default class HelpCenterStatus extends Component<Signature> {
  @service declare helpCenterService: any;

  get hasLiveSites() {
    return this.helpCenterService.allLiveSites.length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::CustomColumnHeaders::HelpCenterStatus': typeof HelpCenterStatus;
    'knowledge-hub/filterable-list/table/custom-column-headers/help-center-status': typeof HelpCenterStatus;
  }
}
