/* import __COLOCATED_TEMPLATE__ from './date-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { type Predicate } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  predicates?: Array<Predicate>;
  datesFilterAttributes: any;
  activeFilter?: string;
  filterContent: (filter: string, predicates?: Array<Predicate>) => void;
  removeFilter: (date?: string) => void;
}

export default class DateFilter extends Component<Args> {
  get isInitiallyClosed() {
    return this.args.activeFilter !== 'date' || !!this.args.predicates;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::DateFilter': typeof DateFilter;
    'knowledge-hub/filterable-list/filters/date-filter': typeof DateFilter;
  }
}
