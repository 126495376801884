/* import __COLOCATED_TEMPLATE__ from './help-center-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type ArticleContent from 'embercom/models/articles/article-content';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import type MutableArray from '@ember/array/mutable';
import type HelpCenterSite from 'embercom/models/help-center-site';
import type ArticleGroup from 'embercom/models/articles/article-group';
import {
  type CollectionWithLevel,
  indentCollections,
} from 'embercom/components/articles/editor/settings-side-drawer/multi-article-group-selector-helper';
import type IntlService from 'embercom/services/intl';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import type ArticleMultilingual from 'embercom/models/article-multilingual';
import { type HelpCenterSelection } from '../edit/help-center-modal';
import { CAN_MANAGE_ARTICLES_PERMISSION } from 'embercom/lib/articles/constants';
import TruncateString from 'embercom/lib/truncate-string';

export const NEW_SELECTOR = 'new-selector';

export const MAXIMUM_OPTION_LABEL_SIZE = 60;

interface Args {
  activeContent: ArticleContent;
  article: ArticleMultilingual;
  displayColumn?: boolean;
  displayModal?: boolean;
  updateSelection?: (selection: HelpCenterSelection) => void;
  setParentModalVisibility?: (value: boolean) => void;
  inModalContext?: boolean;
}

export type CollectionOption = {
  text: string;
  value: string;
  isSelected: boolean;
  component: string;
  tooltipText?: string;
  componentAttrs?: { indentLevel: number };
};

export type HelpCenterOption = {
  text: string;
  tooltipText: string;
  value: string;
  isDisabled: boolean;
  turnedOn: boolean;
  websiteTurnedOn: boolean;
  component: string;
  url: string;
};

type HelpCentersToCollectionsMap = Map<string, CollectionOption[]>;
type CollectionsToHelpCenters = Map<string, string>;

export default class HelpCenterSelector extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare helpCenterService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @service declare permissionsService: {
    checkPermission: Function;
  };

  @tracked helpCenterSelectedWithoutCollection?: string = undefined;

  @tracked showCreateCollectionModal = false;
  @tracked createCollectionModalHcId: string | undefined;
  @action closeCreateCollectionModal() {
    this.showCreateCollectionModal = false;
    this.createCollectionModalHcId = undefined;
    this.args.setParentModalVisibility?.(false);
  }

  helpCentersToCollections: HelpCentersToCollectionsMap = new Map();
  collectionsToHelpCenters: CollectionsToHelpCenters = new Map();

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.initCollectionsMappings();
  }

  get app() {
    return this.appService.app;
  }

  get helpCenters(): MutableArray<HelpCenterSite> {
    return this.helpCenterService.allSites;
  }

  get showAddToHelpCenterButton(): boolean {
    return (
      this.args.activeContent.isEditable &&
      (this.helpCenters.length as number) > this.collectionHelpCenterIdMap.size &&
      this.selectedHelpCenters.length > 0
    );
  }

  get helpCenterOptions() {
    return this.helpCenters.map((helpCenter: HelpCenterSite) => ({
      text: TruncateString(helpCenter.name, MAXIMUM_OPTION_LABEL_SIZE, true),
      tooltipText: helpCenter.name.length > MAXIMUM_OPTION_LABEL_SIZE ? helpCenter.name : undefined,
      value: helpCenter.id,
      isDisabled: this.selectedHelpCenters.includes(helpCenter.id),
      turnedOn: helpCenter.turnedOn,
      websiteTurnedOn: helpCenter.websiteTurnedOn,
      component: 'articles/editor/settings-side-drawer/help-center-option',
      url: helpCenter.url,
    }));
  }

  get selectedHelpCenters(): Array<string> {
    return Array.from(this.collectionHelpCenterIdMap.keys());
  }

  get allHelpCentersSelected(): boolean {
    return this.helpCenters.length === this.selectedHelpCenters.length;
  }

  get selectableTuples(): Array<[string | undefined, string | undefined]> {
    let tuples: [string | undefined, string | undefined][] = Array.from(
      this.collectionHelpCenterIdMap.entries(),
    );
    if (
      !!this.helpCenterSelectedWithoutCollection &&
      this.helpCenterSelectedWithoutCollection !== NEW_SELECTOR
    ) {
      tuples.push([this.helpCenterSelectedWithoutCollection, undefined]);
    } else if (
      (tuples.length === 0 || this.helpCenterSelectedWithoutCollection === NEW_SELECTOR) &&
      !this.allHelpCentersSelected
    ) {
      tuples.push([undefined, undefined]);
    }
    return tuples;
  }

  get collectionHelpCenterIdMap() {
    let map = new Map<string, string | undefined>();
    let collections = this.args.article.inCollections;
    collections.forEach((collection: ArticleGroup) => {
      let helpCenterId = collection.helpCenterId;
      let collectionId = collection.id;
      map.set(helpCenterId, collectionId);
    });
    return map;
  }

  get hasMultipleHelpCenters() {
    return this.helpCenterService.allSites.length > 1;
  }

  get hasLiveHelpCenter() {
    return this.helpCenterService.allLiveSites.length > 0;
  }

  get defaultHelpCenter() {
    return this.helpCenterService.allSites.firstObject;
  }

  get showUnlistedPublishedUrl() {
    return (
      this.args.activeContent.isPublished &&
      this.hasLiveHelpCenter &&
      !this.args.activeContent.isInHelpCenter
    );
  }

  get showHelpCenterUrl() {
    return this.args.activeContent.isPublished;
  }

  get currentlySelectedLocale() {
    return this.args.activeContent.locale;
  }

  @action onClickCreateNewCollectionSingle() {
    this.onClickCreateNewCollection(undefined);
    this.args.setParentModalVisibility?.(true);
  }

  @action onClickCreateNewCollection(hcId?: string) {
    this.createCollectionModalHcId = hcId;
    this.showCreateCollectionModal = true;
    this.args.setParentModalVisibility?.(true);
  }

  @action onHelpCenterSelect(helpCenterId: string, oldHelpCenterId?: string) {
    this.knowledgeHubEditorService.trackAnalyticsEvent('selected_help_center_dropdown_option');
    if (oldHelpCenterId) {
      let removingCollectionId = this.collectionHelpCenterIdMap.get(oldHelpCenterId);
      if (removingCollectionId) {
        let collection = this.store.peekRecord('articles/article-group', removingCollectionId);
        this.args.article.inCollections.removeObject(collection);
      }
    }
    this.helpCenterSelectedWithoutCollection = helpCenterId;
  }

  @action onCollectionCreated(collectionId: string) {
    if (this.createCollectionModalHcId) {
      this.onCollectionMultiSelect(this.createCollectionModalHcId, collectionId);
      return;
    }
    this.onCollectionSingleSelect([collectionId]);
  }

  @action onCollectionSingleSelect(groupIds: string[]) {
    this.args.article.inCollections.clear();
    if (groupIds.length !== 0) {
      this.onCollectionSelected(groupIds[0]);
    } else if (this.args.displayColumn) {
      this.updateArticle();
    }
  }

  @action onCollectionMultiSelect(helpCenterId: string, collectionId: string) {
    if (helpCenterId === this.helpCenterSelectedWithoutCollection) {
      this.helpCenterSelectedWithoutCollection = undefined;
    }
    this.onCollectionSelected(collectionId);
  }

  @action onCollectionSelected(collectionId: string) {
    this.knowledgeHubEditorService.trackAnalyticsEvent('selected_collection_dropdown_option');
    let collection = this.store.peekRecord('articles/article-group', collectionId);
    this.args.article.inCollections.pushObject(collection);
    if (this.args.updateSelection) {
      this.args.updateSelection({ selectedTuples: this.collectionHelpCenterIdMap });
    }
    if (this.args.displayColumn) {
      this.updateArticle();
    }
  }

  @action removeTuple(helpCenterId: string) {
    let collectionId = this.collectionHelpCenterIdMap.get(helpCenterId);
    if (collectionId) {
      let collection = this.store.peekRecord('articles/article-group', collectionId);
      this.args.article.inCollections.removeObject(collection);
    }
    if (this.helpCenterSelectedWithoutCollection === helpCenterId) {
      this.helpCenterSelectedWithoutCollection = undefined;
    }
    if (this.selectableTuples.length === 0) {
      this.helpCenterSelectedWithoutCollection = NEW_SELECTOR;
    }
    if (this.args.updateSelection) {
      this.args.updateSelection({ selectedTuples: this.collectionHelpCenterIdMap });
    }
    if (this.args.displayColumn) {
      this.updateArticle();
    }
  }

  @action collectionOptions(helpCenterId: string) {
    if (
      this.args.article.inCollections
        .map((col) => col.id)
        .any((id) => !this.collectionHelpCenterIdMap.get(id))
    ) {
      // if any of the collections the article belongs don't have a helpcenter, it means the map is outdated and need to be regenerated
      this.initCollectionsMappings();
    }
    return this.helpCentersToCollections.get(helpCenterId) || [];
  }

  @action
  async addToHelpCenter() {
    try {
      await this.enforcePermissions();
    } catch (e) {
      return;
    }
    this.helpCenterSelectedWithoutCollection = NEW_SELECTOR;
  }

  @action
  async updateArticle() {
    await this.knowledgeHubEditorService.updateHelpCenterAndCollection(this.args.article);
    this.notificationsService.notifyConfirmation(
      this.intl.t('articles.editor.side-drawer.settings.article-settings-updated'),
    );
  }

  private async enforcePermissions() {
    await this.permissionsService.checkPermission(CAN_MANAGE_ARTICLES_PERMISSION);
  }

  private initCollectionsMappings() {
    let helpCentersToCollections: HelpCentersToCollectionsMap = new Map();
    let collectionsToHelpCenters: CollectionsToHelpCenters = new Map();

    let helpCenterCollector: Map<string, ArticleGroup[]> = new Map();
    let articleGroups = this.store.peekAll('articles/article-group');
    articleGroups
      .filter((collection: ArticleGroup) =>
        // Normally we want to filter out read-only collections, but we need them to render the disabled dropdown if the article is read-only.
        this.args.article.readOnly ? true : !collection.readOnly,
      )
      .forEach((collection: ArticleGroup) => {
        let helpCenterId = collection.helpCenterId;
        collectionsToHelpCenters.set(collection.id, collection.helpCenterId);

        let collectionsArray = helpCenterCollector.get(helpCenterId) ?? [];
        collectionsArray.push(collection);
        helpCenterCollector.set(helpCenterId, collectionsArray);
      });

    helpCenterCollector.forEach((collections, helpCenterId) => {
      helpCentersToCollections.set(
        helpCenterId,
        indentCollections(collections).map((collection: CollectionWithLevel) =>
          this.makeCollectionOption(collection),
        ),
      );
    });

    this.helpCentersToCollections = helpCentersToCollections;
    this.collectionsToHelpCenters = collectionsToHelpCenters;
  }

  get showCreateCollectionButton() {
    // we check if there is any collection but "home"
    return (
      this.store
        .peekAll('articles/article-group')
        ?.filter((collection: ArticleGroup) => !collection.readOnly)?.length < 2
    );
  }

  get setupContext() {
    return {
      localeId: this.currentlySelectedLocale,
      onCreate: this.onCollectionCreated,
      selectedHc: this.createCollectionModalHcId,
      inModalContext: this.args.inModalContext,
      eventSource: this.args.inModalContext
        ? 'publish-article-modal'
        : 'create-collection-from-dropdown',
    };
  }

  private makeCollectionOption(augmentedCollection: CollectionWithLevel): CollectionOption {
    let name = `${augmentedCollection.collection.get('name')}`;
    return {
      text: TruncateString(name, MAXIMUM_OPTION_LABEL_SIZE, false),
      tooltipText: name.length > MAXIMUM_OPTION_LABEL_SIZE ? name : undefined,
      value: augmentedCollection.collection.id,
      isSelected: this.args.article.inCollections.includes(augmentedCollection.collection),
      component: 'articles/editor/settings-side-drawer/indented-collection-option',
      componentAttrs: { indentLevel: augmentedCollection.level },
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::SidePanel::HelpCenterSelector': typeof HelpCenterSelector;
    'knowledge-hub/content-editor/article-content/side-panel/help-center-selector': typeof HelpCenterSelector;
  }
}
