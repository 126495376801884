/* import __COLOCATED_TEMPLATE__ from './overlapping-plan.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type IntershopService from 'embercom/services/intershop-service';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import type Breakdown from 'embercom/models/billing/price/breakdown';

interface Args {
  planPrice: Breakdown;
  isSeatMetric: boolean;
}

export default class OverlappingPlanComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare intershopService: IntershopService;

  get pricingMetric(): PricingMetric {
    return new PricingMetric(this.args.planPrice.charges.firstObject);
  }

  get pricePerUnit(): string {
    return this.intershopService.formatPriceFromCents(this.pricingMetric.perUnitPrice);
  }

  get formattedTotal(): string {
    return this.intershopService.formatPriceFromCents(this.args.planPrice.amount);
  }

  get usage(): number | undefined {
    return this.args.planPrice.charges?.firstObject?.actual_usage;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingSummary::Order::Tooltip::OverlappingPlan': typeof OverlappingPlanComponent;
    'intershop/pricing/pricing-summary/order/tooltip/overlapping-plan': typeof OverlappingPlanComponent;
  }
}
