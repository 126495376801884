/* import __COLOCATED_TEMPLATE__ from './status.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';

interface Args {
  activeContent: LocalizedKnowledgeContent;
}

const Status = templateOnlyComponent<Args>();
export default Status;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::Status': typeof Status;
    'knowledge-hub/content-editor/shared/data-section/status': typeof Status;
  }
}
