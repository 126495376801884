/* import __COLOCATED_TEMPLATE__ from './tagging-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';
import {
  type Taggable,
  type LocalizedKnowledgeContent,
} from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import type Model from '@ember-data/model';
import type Tag from 'embercom/models/tag';
import { tracked } from '@glimmer/tracking';
import escapeRegExp from 'embercom/lib/escape-regular-expression';
import type Store from '@ember-data/store';
import { type Router } from '@ember/routing';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';

type RenderableTag = {
  id: string;
  name: string;
  selected: boolean;
};

interface Args {
  content: LocalizedKnowledgeContent & Model;
  type: string;
  onClose?: () => void;
  title: string;
}

interface Signature {
  Args: Args;
}

export default class TaggingModal extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;
  @service declare router: Router;
  @service declare intercomEventService: any;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;
  @tracked highlightIndex = -1;
  @tracked query = '';
  @tracked allTags: RenderableTag[] = this.initTagsList();

  private initTagsList() {
    let selectedTags = this.taggable?.tags;
    let renderableTags = this.tags.map((tag: Tag) => {
      return {
        id: tag.id,
        name: tag.name,
        selected: selectedTags?.includes(tag) || false,
      };
    });
    return renderableTags.sort((a: RenderableTag, b: RenderableTag) => {
      if (a.selected !== b.selected) {
        return b.selected ? 1 : -1;
      }

      return a.name.localeCompare(b.name);
    });
  }

  get app() {
    return this.appService.app;
  }

  get tags() {
    return this.app.tags;
  }

  get taggable(): Taggable | undefined {
    return this.args.content.taggable;
  }

  get listOfTags() {
    if (this.query) {
      return this.matchingTags;
    } else {
      return this.allTags;
    }
  }

  get matchingTags() {
    let query = this.query.trim();
    let escaped = escapeRegExp(query);
    let regex = new RegExp(escaped, 'i');
    return this.allTags.filter((tag) => {
      return regex.test(tag.name);
    });
  }

  get allowTagManagement() {
    return this.app.currentAdmin.canManageTags;
  }

  get createNewTagVisible() {
    if (!this.allowTagManagement) {
      return false;
    }
    if (!this.query) {
      return false;
    }

    let query = this.query.toLowerCase();

    return !this.app.tags.any((tag: Tag) => {
      return query === tag.get('name').toLowerCase();
    });
  }

  get createTagLabel() {
    return this.intl.t('knowledge-hub.content-editor.shared.tags.modal.create-tag', {
      tagName: this.query,
    });
  }

  @action
  updateQuery(query: string) {
    this.query = query;
  }

  @action selectTag(tag: { id: string; name: string; selected: boolean }) {
    if (tag.selected) {
      let t = this.tags.findBy('id', tag.id);
      this.args.content.taggable?.tags.removeObject(t);
      this.updateTags([], [t]);
    } else {
      let t = this.tags.findBy('id', tag.id);
      this.args.content.taggable?.tags.pushObject(t);
      this.updateTags([t], []);
    }
    this.resetQuery();
  }

  @action resetQuery() {
    if (this.query !== '') {
      this.allTags = this.initTagsList();
      this.updateQuery('');
    }
  }

  @action async updateTags(addedTags: Tag[], removedTags: Tag[]) {
    let currentAdmin = this.app.currentAdmin;

    this.updateSelectedTags(addedTags[0], removedTags[0]);
    await this.args.content.taggable?.updateTaggings(currentAdmin, addedTags, removedTags);
    this.knowledgeHubEditorService.onContentUpdateCallback?.({
      type: 'edit',
      content: this.knowledgeHubEditorService.activeContentModel,
    });
    this.trackTag();
  }

  private updateSelectedTags(addedTag?: Tag, removedTag?: Tag) {
    this.allTags = this.allTags.map((tag: RenderableTag) => {
      if (tag.id === addedTag?.id) {
        return {
          ...tag,
          selected: true,
        };
      } else if (tag.id === removedTag?.id) {
        return {
          ...tag,
          selected: false,
        };
      } else {
        return tag;
      }
    });
  }

  @action async createAndAddTag() {
    let newTag = await this.store.createRecord('tag', {
      name: this.query,
      app_id: this.app.id,
    });
    await newTag.save();
    this.args.content.taggable?.tags.pushObject(newTag);
    this.app.tags.pushObject(newTag);
    this.allTags.push({
      id: newTag.id,
      name: newTag.name,
      selected: true,
    });
    this.resetQuery();
    this.updateTags([newTag], []);
  }

  private trackTag() {
    this.intercomEventService.trackEvent(`${this.args.type}-tagged`, {
      action: 'tagged',
      object: this.args.type,
      place: 'knowledge-hub',
      message_id: this.args.content.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Tags::TaggingModal': typeof TaggingModal;
    'knowledge-hub/content-editor/shared/tags/tagging-modal': typeof TaggingModal;
  }
}
