/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type ArticleContent from 'embercom/models/articles/article-content';

interface Args {
  activeContent: ArticleContent;
  defaultThemeColor: string;
}

export default class Editor extends Component<Args> {
  @service declare helpCenterService: any;
  @service declare appService: $TSFixMe;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ArticleContent::View::Editor': typeof Editor;
    'knowledge-hub/content-editor/article-content/view/editor': typeof Editor;
  }
}
