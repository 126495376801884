/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type ExternalContent from 'embercom/models/external-content';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  activeContent: ExternalContent;
  defaultThemeColor: string;
}

export default class Editor extends Component<Args> {
  @service declare appService: $TSFixMe;
  @tracked selectedContentPreviewType = 'readable';

  get app() {
    return this.appService.app;
  }

  @action selectContentPreviewType(contentPreviewType: string): void {
    this.selectedContentPreviewType = contentPreviewType;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ExternalContent::View::Editor': typeof Editor;
    'knowledge-hub/content-editor/external-content/view/editor': typeof Editor;
  }
}
