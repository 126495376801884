/* import __COLOCATED_TEMPLATE__ from './plan-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { CORE_START_ID, CORE_GROW_ID } from 'embercom/lib/billing';
import { planCardData } from 'embercom/lib/intershop/intershop';
import type Plan from 'embercom/models/plan';
import type Product from 'embercom/models/product';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';

const PRODUCT_ROUTE = 'apps.app.intershop.products.product';
interface Args {
  product: Product;
  plan: Plan;
  isSalesforceContracted: boolean;
}
export default class PlanCardComponent extends Component<Args> {
  @service declare router: RouterService;

  /**
   * for VBP 1.X
   *  - hide prefix for Grow if salesforce contracted as it is the lowest shown plan
   *  - hide prefix for Start if not salesforce contracted as it is the lowest plan
   */
  get hiddenPrefixes(): Array<string> {
    return this.args.isSalesforceContracted ? [CORE_GROW_ID] : [CORE_START_ID];
  }

  get planDetails(): any {
    return planCardData(this.args.plan.id);
  }

  get showPlanPrefix(): boolean {
    return !this.hiddenPrefixes.includes(this.args.plan.id);
  }

  get billablePlanTierId(): number {
    return this.args.product.billableCustomerPlan?.tierId ?? 0;
  }

  get shouldShowStampTextKey(): boolean {
    return this.args.plan.tierId > this.billablePlanTierId;
  }

  @action
  transitionToProduct(): void {
    this.router.transitionTo(PRODUCT_ROUTE, this.args.product.slug);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Home::PlanCard': typeof PlanCardComponent;
    'intershop/home/plan-card': typeof PlanCardComponent;
  }
}
