/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import templateOnlyComponent from '@ember/component/template-only';
import type InternalArticle from 'embercom/models/content-service/internal-article';

interface Args {
  activeContent: InternalArticle;
  showSidePanel: boolean;
  cancelEditActiveContent: () => void;
  saveActiveContent: () => void;
  publishActiveContent: () => void;
  toggleSidePanel: () => void;
}

const Header = templateOnlyComponent<Args>();
export default Header;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::InternalNote::Edit::Header': typeof Header;
    'knowledge-hub/content-editor/internal-note/edit/header': typeof Header;
  }
}
