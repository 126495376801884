/* import __COLOCATED_TEMPLATE__ from './data-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type ExternalContent from 'embercom/models/external-content';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    activeContent: ExternalContent;
  };
}

export default class DataSection extends Component<Signature> {
  get content() {
    return this.args.activeContent;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Snippet::DataSection': typeof DataSection;
    'knowledge-hub/content-editor/snippet/data-section': typeof DataSection;
  }
}
