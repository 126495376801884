/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { inject as service } from '@ember/service';

interface Args {
  section: $TSFixMe;
}

export default class KnowledgeHubFolderList extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;

  get foldersLoading() {
    return (
      !this.knowledgeHubService.knowledgeTree ||
      this.knowledgeHubService.knowledgeTree.foldersLoading
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Folders::List': typeof KnowledgeHubFolderList;
  }
}
