/* import __COLOCATED_TEMPLATE__ from './show-history.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';

import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ArticleContent from 'embercom/models/articles/article-content';
import type { LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import BrowserLocales from 'embercom/lib/browser-locales';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
interface Signature {
  activeContent: LocalizedKnowledgeContent;
  languageCode: string | undefined;
}

export default class ShowHistory extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare helpCenterService: any;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;

  get app() {
    return this.appService.app;
  }

  get selectedLocaleName() {
    return BrowserLocales[this.args.languageCode || 'en'];
  }

  get currentlySelectedLocaleId() {
    return this.helpCenterService.currentlySelectedLocaleId;
  }

  get site() {
    return this.helpCenterService.site;
  }

  get content() {
    return this.args.activeContent as ArticleContent;
  }

  @action
  showHistory() {
    this.knowledgeHubEditorService.showHistoryOverlay = true;
  }

  @action
  closeHistory() {
    this.knowledgeHubEditorService.showHistoryOverlay = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::ShowHistory': typeof ShowHistory;
    'knowledge-hub/content-editor/shared/data-section/show-history': typeof ShowHistory;
  }
}
