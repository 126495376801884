/* import __COLOCATED_TEMPLATE__ from './static-usage-based.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import type Product from 'embercom/models/product';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';
import type Plan from 'embercom/models/plan';
import type IntershopService from 'embercom/services/intershop-service';

interface Args {
  product: Product;
  plan: Plan;
  pricingMetric: PricingMetric;
}

export default class StaticUsageBasedComponent extends Component<Args> {
  @service declare router: Router;
  @service customerService: any;
  @service declare intershopService: IntershopService;

  ROUTE = 'apps.app.intershop.products.product.plans.plan';

  get usageCount(): number {
    return this.intershopService.contractUsageLimits[this.args.pricingMetric.metric];
  }

  get overlappingProductPlanName(): string | null {
    return this.overlappingPlan
      ? `${this.overlappingPlan.product.name} ${this.overlappingPlan.name}`
      : null;
  }

  get overlappingPlan(): Plan {
    let overlappingPlan = this.customerService
      .getPlansWithOverlappingPricingMetrics(this.args.plan)
      .map((id: number) =>
        this.customerService.plans.find((plan: Plan) => {
          return Number(plan.id) === id && !plan.product.addon;
        }),
      ).firstObject;
    return overlappingPlan;
  }

  @action
  transitionToProduct() {
    if (this.overlappingPlan) {
      this.router.transitionTo(
        this.ROUTE,
        this.overlappingPlan.product.get('slug'),
        this.overlappingPlan.get('slug'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingMetrics::StaticUsageBased': typeof StaticUsageBasedComponent;
    'intershop/pricing/pricing-metrics/static-usage-based': typeof StaticUsageBasedComponent;
  }
}
