/* import __COLOCATED_TEMPLATE__ from './suggestion-card.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type BlockGroup } from '../shared/suggestion-editor';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import Conversation from 'embercom/objects/inbox/conversation';
import { type StreamResourceManager } from 'embercom/components/reporting/custom/report/drill-in-preview-panel';
import { ReviewType } from 'embercom/models/content-service/content-review-request';
import { Status } from 'embercom/models/data/content-service/content-review-statuses';
interface Args {
  blockGroup: BlockGroup;
}

export default class SuggestionCard extends Component<Args> {
  @service declare intl: IntlService;
  @service declare navbarCollapsingService: $TSFixMe;

  @action
  handleSuggestion(acceptSuggestion: boolean) {
    this.args.blockGroup.reviewRequest?.set(
      'status',
      acceptSuggestion ? Status.Approved : Status.Rejected,
    );
  }

  get reviewRequestCreatedAt() {
    if (this.args.blockGroup.reviewRequest) {
      return this.intl.t('knowledge-hub.content-editor.review.suggestion-card.created-from', {
        date: this.intl.formatRelative(this.args.blockGroup.reviewRequest.get('createdAt')),
      });
    }
    return '';
  }

  get showCard() {
    return (
      this.args.blockGroup.isEdited ||
      this.args.blockGroup.reviewRequest?.get('reviewType') === ReviewType.CREATE_CONTENT
    );
  }

  get showEditActionButtons() {
    return this.args.blockGroup.reviewRequest?.get('reviewType') === ReviewType.EDIT_CONTENT;
  }

  @action
  openConversations(streamResourceManager: StreamResourceManager) {
    let suggestion = this.args.blockGroup.reviewRequest?.get('suggestion');
    if (suggestion) {
      taskFor(streamResourceManager.showPreviewSideBar).perform(
        suggestion.includedConversationIds[0],
        Conversation,
        suggestion.includedConversationIds,
      );
    }
  }

  @action
  onCloseConversationSideBar() {
    if (this.navbarCollapsingService.collapsed) {
      this.navbarCollapsingService.toggleNavbarCollapsed();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ContentReview::SuggestionCard': typeof SuggestionCard;
    'knowledge-hub/content-editor/content-review/suggestion-card': typeof SuggestionCard;
  }
}
