/* import __COLOCATED_TEMPLATE__ from './written-by-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import type Admin from 'embercom/models/admin';

interface Args {
  doSearch: (collectionIds?: string[]) => void;
  selectedValues?: string[];
  activeFilter?: string;
  removeFilter: (copilotState?: string) => void;
}

export default class WrittenByFilter extends Component<Args> {
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  @tracked selectedValues: string[] = this.args.selectedValues || [];

  get app() {
    return this.appService.app;
  }

  get isOpen() {
    return this.args.activeFilter === 'writtenByIds' && !this.args.selectedValues;
  }

  get details() {
    let count = this.selectedValues.length;

    return this.intl.t('articles.shared.last_edited_by_filter.details', {
      firstItem: count ? this.getDisplayName(this.selectedValues[0]) : '',
      count,
      additional: count - 1,
    });
  }

  private getDisplayName(userId: string) {
    let matched = this.app.assignableAdminsWithoutUnassigned.find(
      (user: Admin) => user.id === userId,
    );
    if (!matched) {
      return '';
    }
    return matched.name;
  }

  @action onSelectionChange(selectedValues: string[]) {
    this.selectedValues = selectedValues;

    if (this.selectedValues.length === 1 && this.selectedValues[0] === '') {
      this.args.doSearch([]);
      return;
    }
    if (this.selectedValues.length === 0) {
      this.args.doSearch(undefined);
      return;
    }
    this.args.doSearch(this.selectedValues);
  }

  @action removeFilter() {
    this.args.doSearch(undefined);
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::WrittenByFilter': typeof WrittenByFilter;
    'knowledge-hub/filterable-list/filters/written-by-filter': typeof WrittenByFilter;
  }
}
