/* import __COLOCATED_TEMPLATE__ from './segments-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type AiContentSegmentsService from 'embercom/services/ai-content-segments-service';
import { MAX_NB_CHARS_FOR_AUDIENCES_NAME } from 'embercom/services/ai-content-segments-service';
import type AiContentSegment from 'embercom/models/ai-content-segment';

interface Args {
  aiContentSegmentIds: [number];
  isMixed: boolean;
  isFolder: boolean;
}

const NUMBER_OF_SEGMENTS_TO_DISPLAY = 2;

export default class SegmentsCell extends Component<Args> {
  @service declare aiContentSegmentsService: AiContentSegmentsService;

  get names(): Array<string> {
    let res = this.args.aiContentSegmentIds?.map((id: number) => {
      let name =
        this.aiContentSegmentsService.segments.find(
          (segment: AiContentSegment) => Number(segment.id) === Number(id),
        )?.nameWithFallback ?? '';

      if (name.length > MAX_NB_CHARS_FOR_AUDIENCES_NAME) {
        name = `${name.substring(0, MAX_NB_CHARS_FOR_AUDIENCES_NAME)}...`;
      }
      return name;
    });
    return res || [];
  }

  get namesToDisplay(): Array<string> {
    return this.names.slice(0, NUMBER_OF_SEGMENTS_TO_DISPLAY);
  }

  get moreCount(): number {
    return this.names.length - NUMBER_OF_SEGMENTS_TO_DISPLAY;
  }

  get moreNames() {
    return this.names.slice(NUMBER_OF_SEGMENTS_TO_DISPLAY, this.names.length);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::SegmentsCell': typeof SegmentsCell;
    'knowledge-hub/filterable-list/table/cells/SegmentsCell': typeof SegmentsCell;
  }
}
