/* import __COLOCATED_TEMPLATE__ from './data-section.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';
import type ExternalContent from 'embercom/models/external-content';

interface Args {
  activeContent: ExternalContent;
}

const DataSection = templateOnlyComponent<Args>();
export default DataSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::ExternalContent::DataSection': typeof DataSection;
    'knowledge-hub/content-editor/external-content/data-section': typeof DataSection;
  }
}
