/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { addonData } from 'embercom/lib/intershop/intershop';
import type Plan from 'embercom/models/plan';
import type Product from 'embercom/models/product';

interface Args {
  product: Product;
}

export default class OverviewComponent extends Component<Args> {
  get dataHorizontal() {
    return addonData(this.plan.id)?.horizontal;
  }

  get dataVertical() {
    return addonData(this.plan.id)?.vertical;
  }

  get plan(): Plan {
    return this.args.product.plans.firstObject!;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::AddOns::Overview::Overview': typeof OverviewComponent;
    'intershop/products/add-ons/overview/overview': typeof OverviewComponent;
  }
}
