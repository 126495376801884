/* import __COLOCATED_TEMPLATE__ from './move-to-collection-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type HelpCenterSite from 'embercom/models/help-center-site';
import TruncateString from 'embercom/lib/truncate-string';
import {
  type CollectionOption,
  MAXIMUM_OPTION_LABEL_SIZE,
} from '../../content-editor/article-content/side-panel/help-center-selector';
import type ArticleGroup from 'embercom/models/articles/article-group';
import {
  type CollectionWithLevel,
  HOME_IDENTIFIER,
  indentCollections,
} from 'embercom/components/articles/editor/settings-side-drawer/multi-article-group-selector-helper';

interface Args {
  onClose: () => void;
  isBulkAction?: boolean;
  isBulkActionRunning?: boolean;
  performBulkMoveToCollection: (
    collectionId?: number | string,
    helpCenterId?: number | string,
  ) => void;
}

type HelpCenterOption = {
  text: string;
  value: string;
  websiteTurnedOn: boolean;
  tooltipText?: string;
  component?: string;
};

type HelpCentersToCollectionsMap = Map<string, CollectionOption[]>;
type CollectionsToHelpCenters = Map<string, string>;

export default class MoveToCollectionModal extends Component<Args> {
  @service declare helpCenterService: any;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked selectedHelpCenterId: string | undefined;
  @tracked selectedCollectionId: string | undefined;

  helpCentersToCollections: HelpCentersToCollectionsMap = new Map();
  collectionsToHelpCenters: CollectionsToHelpCenters = new Map();

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.initMappings();
    if (!this.hasMultipleHelpCenters) {
      this.selectedHelpCenterId = this.helpCenters.firstObject?.id;
    }
  }

  get prompt() {
    return this.hasMultipleHelpCenters
      ? this.intl.t('knowledge-hub.filterable-list.bulk-actions.move-to-collection.multi-hc-prompt')
      : this.intl.t(
          'knowledge-hub.filterable-list.bulk-actions.move-to-collection.single-hc-prompt',
        );
  }

  get helpCenters(): HelpCenterSite[] {
    return this.helpCenterService.allSites;
  }

  get hasMultipleHelpCenters() {
    return this.helpCenters.length > 1;
  }

  get showCollectionSelector() {
    return this.selectedHelpCenterId && this.selectedHelpCenterId !== HOME_IDENTIFIER;
  }

  get helpCenterOptions(): HelpCenterOption[] {
    let helpCenterOptions = this.helpCenters.map(
      (helpCenter: HelpCenterSite) =>
        ({
          text: TruncateString(helpCenter.name, MAXIMUM_OPTION_LABEL_SIZE, true),
          tooltipText:
            helpCenter.name.length > MAXIMUM_OPTION_LABEL_SIZE ? helpCenter.name : undefined,
          value: helpCenter.id,
          websiteTurnedOn: helpCenter.websiteTurnedOn,
          component: undefined,
        }) as HelpCenterOption,
    );

    helpCenterOptions.unshift({
      text: this.intl.t(
        'knowledge-hub.filterable-list.bulk-actions.move-to-collection.no-help-center',
      ),
      value: HOME_IDENTIFIER,
      websiteTurnedOn: false,
      component: 'articles/editor/settings-side-drawer/no-collection-option',
    });

    return helpCenterOptions;
  }

  get collectionOptions() {
    if (!this.selectedHelpCenterId) {
      return [];
    }

    let options = this.helpCentersToCollections.get(this.selectedHelpCenterId) ?? [];
    if (!this.hasMultipleHelpCenters) {
      options.unshift({
        text: this.intl.t(
          'knowledge-hub.filterable-list.bulk-actions.move-to-collection.no-collection',
        ),
        value: HOME_IDENTIFIER,
        isSelected: false,
        component: 'articles/editor/settings-side-drawer/no-collection-option',
      });
    }
    return options;
  }

  @action onSelectHelpCenter(helpCenterId: string) {
    this.selectedHelpCenterId = helpCenterId;
    this.selectedCollectionId = undefined;
  }

  @action selectCollection(collectionId: string) {
    this.selectedCollectionId = collectionId;
  }

  private initMappings() {
    let helpCenterCollector: Map<string, ArticleGroup[]> = new Map();
    let articleGroups = this.store.peekAll('articles/article-group');

    articleGroups
      .filter((collection: ArticleGroup) => !collection.readOnly)
      .forEach((collection: ArticleGroup) => {
        let helpCenterId = collection.helpCenterId;
        this.collectionsToHelpCenters.set(collection.id, collection.helpCenterId);

        let collectionsArray = helpCenterCollector.get(helpCenterId) ?? [];
        collectionsArray.push(collection);
        helpCenterCollector.set(helpCenterId, collectionsArray);
      });

    helpCenterCollector.forEach((collections, helpCenterId) => {
      this.helpCentersToCollections.set(
        helpCenterId,
        indentCollections(collections).map((collection: CollectionWithLevel) =>
          this.makeCollectionOption(collection),
        ),
      );
    });
  }

  private makeCollectionOption(augmentedCollection: CollectionWithLevel): CollectionOption {
    let name = `${augmentedCollection.collection.get('name')}`;
    return {
      text: TruncateString(name, MAXIMUM_OPTION_LABEL_SIZE, false),
      tooltipText: name.length > MAXIMUM_OPTION_LABEL_SIZE ? name : undefined,
      value: augmentedCollection.collection.id,
      isSelected: false,
      component: 'articles/editor/settings-side-drawer/indented-collection-option',
      componentAttrs: { indentLevel: augmentedCollection.level },
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::BulkActions::MoveToCollectionModal': typeof MoveToCollectionModal;
    'knowledge-hub/filterable-list/bulk-actions/move-to-collection-modal': typeof MoveToCollectionModal;
  }
}
