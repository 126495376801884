/* import __COLOCATED_TEMPLATE__ from './pricing.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Plan from 'embercom/models/plan';
import type Product from 'embercom/models/product';
import type IntershopService from 'embercom/services/intershop-service';
import { PROACTIVE_SUPPORT_BASE_ID, PEOPLE_REACHED_TIER_1_ID } from 'embercom/lib/billing';

interface Args {
  product: Product;
  plan: Plan;
}

export default class PricingComponent extends Component<Args> {
  @service declare customerService: any;
  @service declare intershopService: IntershopService;

  get peopleReachedPlanIds(): number | undefined {
    if (
      this.args.plan.id === PROACTIVE_SUPPORT_BASE_ID &&
      !this.intershopService.hasBillablePeopleReachedTierId()
    ) {
      return Number(PEOPLE_REACHED_TIER_1_ID);
    }
    return;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::AddOns::Pricing::Pricing': typeof PricingComponent;
    'intershop/products/add-ons/pricing/pricing': typeof PricingComponent;
  }
}
