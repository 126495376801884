/* import __COLOCATED_TEMPLATE__ from './slider.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type Metric } from 'embercom/models/data/pricing/metric-types';

interface Args {
  totalBlocks: number;
  index: number;
  onChange: (index: number) => void;
  metric: Metric;
}

export default class SliderComponent extends Component<Args> {
  @service intercomEventService: any;

  get maximum(): number {
    return this.args.totalBlocks - 1;
  }

  @action
  onChange(index: number): void {
    this.args.onChange(index);
    taskFor(this.trackSliderChange).perform();
  }

  @task({ restartable: true })
  *trackSliderChange() {
    yield timeout(2000);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'on_change',
      object: 'intershop_slider',
      section: 'intershop',
      metric: this.args.metric,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingMetrics::UsageSelector::Slider::Slider': typeof SliderComponent;
    'intershop/pricing/pricing-metrics/usage-selector/slider/slider': typeof SliderComponent;
  }
}
