/* import __COLOCATED_TEMPLATE__ from './integration-warning.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';

export type IntegrationWarningReason =
  | 'zendesk-tickets-integration'
  | 'zendesk-fin-user'
  | 'salesforce-integration'
  | 'salesforce-fin-user';

interface Args {
  integrationWarningReason: IntegrationWarningReason;
}

export default class IntegrationWarning extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare router: RouterService;

  get warningText() {
    switch (this.args.integrationWarningReason) {
      case 'zendesk-tickets-integration':
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.integration-warning.no-integration.zendesk',
        );
      case 'zendesk-fin-user':
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.integration-warning.no-fin-id.text',
        );
      case 'salesforce-integration':
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.integration-warning.no-integration.salesforce',
        );
      case 'salesforce-fin-user':
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.integration-warning.no-fin-id.text',
        );
      default:
        return '';
    }
  }

  get warningLinkText() {
    switch (this.args.integrationWarningReason) {
      case 'zendesk-tickets-integration':
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.integration-warning.no-integration.cta',
        );
      case 'zendesk-fin-user':
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.integration-warning.no-fin-id.cta',
        );
      case 'salesforce-integration':
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.integration-warning.no-integration.cta',
        );
      case 'salesforce-fin-user':
        return this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.standalone.integration-warning.no-fin-id.cta',
        );
      default:
        return '';
    }
  }

  @action warningCtaAction() {
    switch (this.args.integrationWarningReason) {
      case 'zendesk-tickets-integration':
        this.router.transitionTo('apps.app.settings.standalone.integration');
        break;
      case 'zendesk-fin-user':
        this.router.transitionTo('apps.app.standalone.deploy.zendesk.tickets');
        break;
      case 'salesforce-integration':
        this.router.transitionTo('apps.app.settings.standalone.integration');
        break;
      case 'salesforce-fin-user':
        this.router.transitionTo('apps.app.standalone.deploy.salesforce.cases');
        break;
      default:
        break;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Conversational::Standalone::IntegrationWarning': typeof IntegrationWarning;
  }
}
