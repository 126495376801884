/* import __COLOCATED_TEMPLATE__ from './open-side-panel-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  onClick: () => void;
}

const OpenSidePanelButton = templateOnlyComponent<Args>();
export default OpenSidePanelButton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Header::OpenSidePanelButton': typeof OpenSidePanelButton;
    'knowledge-hub/content-editor/shared/header/open-side-panel-button': typeof OpenSidePanelButton;
  }
}
