/* import __COLOCATED_TEMPLATE__ from './add-on-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { addonData, addonCardData } from 'embercom/lib/intershop/intershop';
import {
  PRODUCT_TOURS_ID,
  SURVEYS_ID,
  SWITCH_ID,
  SMS_ID,
  SWITCH_BASE_ID,
  SURVEYS_BASE_ID,
  PRODUCT_TOURS_ESSENTIAL_ID,
  FIN_AI_COPILOT_BASE_ID,
} from 'embercom/lib/billing';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'embercom/services/intl';
import type Product from 'embercom/models/product';
import type Plan from 'embercom/models/plan';

const NEW_PRODUCTS = [SURVEYS_ID, SWITCH_ID, SMS_ID];
const PRODUCTS_WITH_EXAMPLE = [PRODUCT_TOURS_ID, SURVEYS_ID, SWITCH_ID, FIN_AI_COPILOT_BASE_ID];

interface Args {
  product: Product;
  plan: Plan;
}

export default class AddonCardComponent extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;

  @tracked exampleModalOpen = false;

  SWITCH_ID = SWITCH_ID;

  get app(): any {
    return this.appService.app;
  }

  get addonDetails(): any {
    return addonCardData(this.args.plan.id);
  }

  get addonDescription(): string {
    if (this.addonDetails && this.addonDetails.landingPageDescription) {
      return this.intl.t(this.addonDetails.landingPageDescription);
    } else {
      return '';
    }
  }

  get addonIcon(): string | null {
    return this.addonDetails?.icon;
  }

  get isNewProduct(): boolean {
    return NEW_PRODUCTS.includes(this.args.product.id);
  }

  get hasExample(): boolean {
    return PRODUCTS_WITH_EXAMPLE.includes(this.args.product.id);
  }

  @action
  viewExample(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    let data = addonData(this.args.plan.id);

    switch (this.args.plan.id) {
      case SWITCH_BASE_ID:
        this.exampleModalOpen = true;
        return;
      case SURVEYS_BASE_ID:
      case PRODUCT_TOURS_ESSENTIAL_ID:
        window.Intercom(data.example!.action, data.example!.id);
        return;
    }
  }

  @action
  onViewExampleModalClose(): void {
    this.exampleModalOpen = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Home::AddOnCard': typeof AddonCardComponent;
    'intershop/home/add-on-card': typeof AddonCardComponent;
  }
}
