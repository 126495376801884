/* import __COLOCATED_TEMPLATE__ from './line-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ContentStatistic from 'embercom/models/outbound/content-statistic';
import type IntlService from 'ember-intl/services/intl';
import {
  STATISTIC_KEY_TO_AGGREGATION_VALUES,
  STATISTIC_KEY_TO_DESCRIPTION_KEY,
} from 'embercom/components/knowledge-hub/content-editor/shared/reporting-section';
import { action } from '@ember/object';
import { type StatisticKeyName } from 'embercom/models/data/outbound/types';

type AggregationItem = {
  value: string;
  count: number;
};

interface Signature {
  Element: HTMLDivElement;
  Args: {
    key: StatisticKeyName;
    contentStat: ContentStatistic | undefined;

    setSelectedTab?: (key: StatisticKeyName) => void;
    openDrawer?: (context: string) => void;
  };
}

export default class LineItem extends Component<Signature> {
  @service declare intl: IntlService;

  get eventNameFromStatKey() {
    let eventNames: Partial<Record<StatisticKeyName, string>> = {
      articleContentView: 'view_stat_link',
      replies: 'conversation_stat_link',
      reactions: 'reaction_stat_link',
    };
    return eventNames[this.args.key] ?? 'view_stat_link';
  }

  @action
  openDrawerWithTabSelected() {
    if (this.args.openDrawer) {
      this.args.setSelectedTab?.(this.args.key);
      this.args.openDrawer(this.eventNameFromStatKey);
    }
  }

  get description() {
    let descriptionKey = STATISTIC_KEY_TO_DESCRIPTION_KEY[this.args.key];
    if (!descriptionKey) {
      return '-';
    }

    return this.intl.t(descriptionKey);
  }

  get value() {
    return this.args.contentStat?.value ?? '-';
  }

  get hasAggregationValues() {
    let aggregationValues = STATISTIC_KEY_TO_AGGREGATION_VALUES[this.args.key] ?? [];

    return !!STATISTIC_KEY_TO_AGGREGATION_VALUES[this.args.key] && aggregationValues.length > 0;
  }

  get subaggregations() {
    let aggregationValues = STATISTIC_KEY_TO_AGGREGATION_VALUES[this.args.key];
    if (!aggregationValues) {
      return [];
    }

    let denominator = this.args.contentStat?.value ?? 0;

    return aggregationValues.map((value) => {
      let count = 0;
      if (denominator > 0) {
        let aggregatedCount =
          this.availableAggregations.find((aggregation) => aggregation.value === value)?.count ?? 0;
        count = Math.round((aggregatedCount * 100) / denominator);
      }
      return { value, count };
    });
  }

  get availableAggregations(): AggregationItem[] {
    if (!this.args.contentStat || !Array.isArray(this.args.contentStat.subaggregations)) {
      return [];
    }

    return this.args.contentStat.subaggregations as AggregationItem[];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::ReportingSection::LineItem': typeof LineItem;
    'knowledge-hub/content-editor/shared/reporting-section/line-item': typeof LineItem;
  }
}
