/* import __COLOCATED_TEMPLATE__ from './title.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';
import { type HighlightedResult } from 'embercom/objects/inbox/knowledge-base/search-result';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    highlightedTitleArray?: HighlightedResult[];
  };
}

const Title = templateOnlyComponent<Signature>();
export default Title;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::SearchHighlight::Title': typeof Title;
    'knowledge-hub/filterable-list/table/cells/search-highlight::Title': typeof Title;
  }
}
