/* import __COLOCATED_TEMPLATE__ from './content-link.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { EntityType } from 'embercom/models/data/entity-types';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';

const FOLDER_ROUTE = 'apps.app.knowledge-hub.folder';

interface Signature {
  Element: HTMLElement;
  Args: {
    entityId: number;
    entityType: EntityType;
  };
  Blocks: any;
}

export default class ContentLink extends Component<Signature> {
  @service declare router: RouterService;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;
  @service declare appService: $TSFixMe;

  get entityType() {
    return this.args.entityType;
  }

  get entityId() {
    return this.args.entityId;
  }

  get entityTypeName(): string {
    return objectNames[this.entityType];
  }

  get isFolder() {
    return this.entityType === EntityType.ContentLibraryFolder;
  }

  get isMacro() {
    return this.entityType === EntityType.SavedReply;
  }

  @action
  handleClick(event?: Event): void {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }

    if (this.isFolder) {
      this.router.transitionTo(FOLDER_ROUTE, this.entityId);
    } else if (this.isMacro) {
      this.router.transitionTo('apps.app.settings.helpdesk.macros');
    } else {
      this.knowledgeHubDrawerEditorService.openViewDrawer({
        activeContentId: this.entityId,
        activeContentType: this.entityTypeName,
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentLink': typeof ContentLink;
    'knowledge-hub/content-link': typeof ContentLink;
  }
}
