/* import __COLOCATED_TEMPLATE__ from './tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    tooltipKey: string;
  };
  Blocks: {
    default: [];
  };
  Element: HTMLElement;
}

const Tooltip = templateOnlyComponent<Signature>();
export default Tooltip;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::Header::Tooltip': typeof Tooltip;
  }
}
