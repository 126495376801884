/* import __COLOCATED_TEMPLATE__ from './seat-selector.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import numberFormatter from 'embercom/lib/number-formatter';
import { FIXED_DISCOUNT_OFFERED } from 'embercom/lib/intershop/intershop';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import type IntershopService from 'embercom/services/intershop-service';
import { inject as service } from '@ember/service';

interface Args {
  pricingMetric: PricingMetric;
  onChange: (usage: number, metric: Metric, dollarDiscountValue: number | undefined) => {};
  isPlanInSubscription: boolean;
  hasActivePlanOnProduct: boolean;
}

export default class SeatSelectorComponent extends Component<Args> {
  @service declare intershopService: IntershopService;
  @tracked seatUsage = this.currentUsage;

  get currentUsage(): number {
    if (this.args.hasActivePlanOnProduct) {
      return this.inOverages ? this.overageTotal : 0;
    }
    return this.minimumMetricUsage();
  }

  get hasSecondaryWorkspace(): boolean {
    return this.intershopService.contract.numberOfSecondarySubscriptions > 0;
  }

  get disableDecrement(): boolean {
    let minimum_threshold = this.args.hasActivePlanOnProduct ? 0 : this.minimumMetricUsage();
    return this.seatUsage <= minimum_threshold;
  }

  get disableIncrement(): boolean {
    // Proactive Support Seats can only ever be <= the total number of Support Seats (incl. overages)
    return (
      this.args.pricingMetric.metric === Metric.proactive_support_seat_count &&
      this.seatUsage >= this.intershopService.billedUsage[Metric.support_seat_count]
    );
  }

  get pricePerSeat(): number {
    return numberFormatter(this.args.pricingMetric.perUnitPrice / 100);
  }

  get dollarDiscountAmount(): number {
    return Math.ceil((this.pricePerSeat / 100.0) * FIXED_DISCOUNT_OFFERED);
  }

  get discountedPricePerSeat(): number {
    // always round discount up to create more incentive to the user
    return Math.floor(this.pricePerSeat - this.dollarDiscountAmount);
  }

  get fixedDiscountOffered(): number {
    return FIXED_DISCOUNT_OFFERED;
  }

  get showContractMinLimitNote(): boolean {
    return this.disableDecrement && this.args.hasActivePlanOnProduct;
  }

  get showRecommendedNote(): boolean {
    return this.args.hasActivePlanOnProduct && this.inOverages;
  }

  get contractedSeatTotal(): number {
    return this.intershopService.contractUsageLimits[this.args.pricingMetric.metric];
  }

  get usedSeatTotal(): number {
    return this.intershopService.totalUsage[this.args.pricingMetric.metric];
  }

  get inOverages(): boolean {
    return this.overageTotal > 0;
  }

  get overageTotal(): number {
    return Math.max(this.usedSeatTotal - this.contractedSeatTotal, 0);
  }

  @action
  increment(): void {
    this.seatUsage++;
    this.onChange();
  }

  @action
  decrement(): void {
    this.seatUsage--;
    this.onChange();
  }

  @action
  onChange(): void {
    this.args.onChange(this.seatUsage, this.args.pricingMetric.metric, this.dollarDiscountAmount);
  }

  minimumMetricUsage(): number {
    return this.args.pricingMetric.minimumUsage;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingMetrics::SeatSelector': typeof SeatSelectorComponent;
    'intershop/pricing/pricing-metrics/seat-selector': typeof SeatSelectorComponent;
  }
}
