/* import __COLOCATED_TEMPLATE__ from './tag-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type Tag from 'embercom/models/tag';
import type IntlService from 'ember-intl/services/intl';
import { type TagOperator } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  activeFilter?: string;
  doSearch: (key?: string, tagIds?: string[]) => void;
  removeFilter: () => void;
  selectedTagValues?: string[];
  tagOperator: TagOperator;
}

export default class TagFilter extends Component<Args> {
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;

  get isOpen() {
    return this.args.activeFilter === 'tag' && !this.args.selectedTagValues?.length;
  }

  get tags() {
    return this.appService.app.tags.reject((tag: Tag) => tag.archived);
  }

  get emptyTagsEntry() {
    return { text: this.intl.t('articles.list.no_tags'), value: 0 };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::TagFilter': typeof TagFilter;
    'knowledge-hub/filterable-list/filters/tag-filter': typeof TagFilter;
  }
}
