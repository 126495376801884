/* import __COLOCATED_TEMPLATE__ from './column-selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import { type ColumnData } from 'embercom/lib/knowledge-hub/constants';
import { type TableColumn } from 'embercom/components/content-service/ai-content-library';

interface Args {
  onSelectionChange: (columns: string[]) => void;
  selectedColumns: TableColumn[];
  selectableColumns: ColumnData[];
}

export default class ColumnSelector extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;

  get columns() {
    return this.args.selectableColumns.map((column) => {
      let data: ColumnData = column;
      if (column.labelIntlKey) {
        data['text'] = this.intl.t(column.labelIntlKey);
      }
      data['isSelected'] = this.args.selectedColumns.some(
        (selectedCol) => selectedCol.valuePath === column.value,
      );
      return data;
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::ColumnSelector': typeof ColumnSelector;
    'knowledge-hub/filterable-list/table/column-selector': typeof ColumnSelector;
  }
}
