/* import __COLOCATED_TEMPLATE__ from './plan-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { planCardData, PRODUCT_FEATURES } from 'embercom/lib/intershop/intershop';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import type CatalogApi from 'embercom/services/catalog-api';
import { taskFor } from 'ember-concurrency-ts';
import type Product from 'embercom/models/product';
import type Plan from 'embercom/models/plan';
import type IntershopService from 'embercom/services/intershop-service';
import { CORE_STARTER_BASE_ID } from 'embercom/lib/billing';

interface Args {
  product: Product;
  plan: Plan;
  higherTierPlan: any;
}

const FULL_PAYWALL_PLAN_IDS = [CORE_STARTER_BASE_ID]; // for paywalls where we want to show the full paywall and not just the sidebar component

export default class PlanCardComponent extends Component<Args> {
  @service declare customerService: any;
  @service declare router: RouterService;
  @service declare catalogApi: CatalogApi;
  @service declare appService: any;
  @service declare intershopService: IntershopService;

  ROUTE = 'apps.app.intershop.products.product.plans.plan';

  get planDetails(): any {
    return planCardData(this.args.plan.id);
  }

  get productFeatureList(): any {
    return PRODUCT_FEATURES[this.args.product.id];
  }

  get canViewIntershopPricingPage(): boolean {
    return this.intershopService.canAccessPricing;
  }

  get isRecommendedTier(): boolean {
    if (this.args.higherTierPlan === undefined) {
      return false;
    }

    return this.args.higherTierPlan.id === this.args.plan.id;
  }

  get isHigherTierPlan(): boolean {
    if (this.args.higherTierPlan === undefined) {
      return false;
    }

    return this.args.plan.tierId >= this.args.higherTierPlan.tierId;
  }

  get canStartSelfServeTrial(): boolean {
    return (
      !this.args.product.isOnTrial && this.isRecommendedTier && this.args.plan.selfServeTrialable
    );
  }

  get showInYourSubscriptionStamp(): boolean {
    return this.args.plan.billableCustomerPlan;
  }

  get showCurrentlyTraillingStamp(): boolean {
    return (
      this.args.plan.isHighestTierOnTrial && !this.args.plan.isLowerTierThanBillableCustomerPlan
    );
  }

  get showContinueToPricingButton(): boolean {
    return this.canViewIntershopPricingPage && this.isHigherTierPlan;
  }

  get showRequestFreeTrialButton(): boolean {
    return (
      this.canViewIntershopPricingPage && !this.args.product.isOnTrial && this.isRecommendedTier
    );
  }

  get showAddMoreUsageButton(): boolean {
    return this.canViewIntershopPricingPage && this.args.plan.billableCustomerPlan;
  }

  get showStartTrialButton(): boolean {
    return (
      !this.canViewIntershopPricingPage &&
      this.canStartSelfServeTrial &&
      this.customerService.customer.hasActiveSubscription
    );
  }

  get showTalkToSalesText(): boolean {
    return !this.canViewIntershopPricingPage && this.canStartSelfServeTrial;
  }

  get showTalkToSalesButton(): boolean {
    return (
      !this.canViewIntershopPricingPage &&
      !this.canStartSelfServeTrial &&
      this.isHigherTierPlan &&
      !this.args.plan.activeTrial &&
      !this.showUpgradeNowButton
    );
  }

  get showOnlyPaywallSidebar(): boolean {
    return !FULL_PAYWALL_PLAN_IDS.includes(this.args.plan.id);
  }

  get showUpgradeNowButton(): boolean {
    return (
      !this.canViewIntershopPricingPage && this.canSelfServeUpgradeToPlan && this.isHigherTierPlan
    );
  }

  @action
  transitionToProduct() {
    this.router.transitionTo(this.ROUTE, this.args.product.slug, this.args.plan.slug);
  }

  @action
  requestFreeTrial() {
    let billableCustomerPlan = this.args.product.billableCustomerPlan;
    let params = {
      app_id: this.appService.app.id,
      product_name: `${this.args.product.name} ${this.args.plan.name}`,
      current_plan: billableCustomerPlan
        ? `${this.args.product.name} ${billableCustomerPlan.name}`
        : // eslint-disable-next-line @intercom/intercom/no-bare-strings
          `No current plan on ${this.args.product.name}`,
      admin_id: this.appService.app.currentAdmin.id,
      admin_email: this.appService.app.currentAdmin.email,
    };
    taskFor(this.catalogApi.requestFreeTrial).perform(params);
  }

  get canSelfServeUpgradeToPlan(): boolean {
    return this.intershopService.canSelfServeUpgradeToPlan(this.args.plan);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::PlanCard': typeof PlanCardComponent;
    'intershop/products/plan-card': typeof PlanCardComponent;
  }
}
