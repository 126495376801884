/* import __COLOCATED_TEMPLATE__ from './field-mapper.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { SourceType } from 'embercom/models/import-service/import-source';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';

interface FieldMapping {
  [key: string]: string[]; // intercom field : [source field]
}

interface GroupItem {
  label?: string;
  items: DropdownItem[];
}

interface DropdownItem {
  id: string;
  label: string;
  text: string;
}

interface IntercomFieldsStructure {
  required: string[];
  optional: string[];
  default: Record<string, string>;
}

interface Args {
  groupList: GroupItem[];
  connectionType: string;
  onFieldMapUpdate: (fieldMap: FieldMapping) => void;
  sourceType: SourceType;
  intercomFields: string[];
  metadata?: any;
}

export default class FieldMapper extends Component<Args> {
  @tracked mappingData: any[] = [];
  @tracked fieldMap: any = {};

  @service declare intl: IntlService;

  private createFieldData(field: string, isRequired: boolean, defaultMapping?: string) {
    let destinationField = defaultMapping || '';
    let destinationFieldName =
      defaultMapping || this.intl.t(`knowledge-hub.sync-modal.field-mapper.field-placeholder`);
    let status = defaultMapping ? 'mapped' : 'pending';

    return {
      status,
      sourceFieldName: field,
      sourceField: field,
      destinationFieldName,
      destinationField,
      fieldType: 'string',
      readOnly: false,
      isRequired,
    };
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    args.groupList.forEach((group) => {
      group.items.sort((a, b) => a.label.localeCompare(b.label));
    });

    let fields = args.intercomFields[0] as unknown as IntercomFieldsStructure;
    let defaultMappings = fields.default || {};

    let requiredFields = fields.required.map((field) =>
      this.createFieldData(field, true, defaultMappings[field]),
    );
    let optionalFields = fields.optional.map((field) =>
      this.createFieldData(field, false, defaultMappings[field]),
    );

    this.mappingData = [...requiredFields, ...optionalFields];

    this.fieldMap = this.createFieldMap();
    this.args.onFieldMapUpdate?.(this.fieldMap);
  }

  @action
  createFieldMap() {
    this.fieldMap = this.mappingData.reduce((acc, mapping) => {
      if (mapping.status === 'mapped') {
        acc[mapping.sourceFieldName] = [mapping.destinationField];
      }
      return acc;
    }, {} as FieldMapping);

    return this.fieldMap;
  }

  @action
  selectItem(rowData: FieldMapping, selectedItem: any) {
    this.mappingData = this.mappingData.map((mapping) =>
      mapping === rowData
        ? {
            ...mapping,
            destinationField: selectedItem.id,
            destinationFieldName: selectedItem.label,
            status: 'mapped',
          }
        : mapping,
    );

    this.fieldMap = this.createFieldMap();
    this.args.onFieldMapUpdate?.(this.fieldMap);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FieldMapper': typeof FieldMapper;
    'knowledge-hub/field-mapper': typeof FieldMapper;
  }
}
