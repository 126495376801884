/* import __COLOCATED_TEMPLATE__ from './parent-folder-link.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import type Folder from 'embercom/models/content-service/folder';
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  folder: Folder;
  showIcon?: boolean;
  truncateShort?: boolean;
}

const ParentFolderLink = templateOnlyComponent<Args>();
export default ParentFolderLink;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Folders::ParentFolderLink': typeof ParentFolderLink;
  }
}
