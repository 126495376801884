/* import __COLOCATED_TEMPLATE__ from './copilot-state-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';

interface Args {
  doSearch: (copilotState?: string) => void;
  selectedCopilotStateValue?: string;
  activeFilter?: string;
  removeFilter: (copilotState?: string) => void;
}

export default class CopilotStateFilter extends Component<Args> {
  @service declare intl: IntlService;

  get isOpen() {
    return this.args.activeFilter === 'copilotState' && !this.args.selectedCopilotStateValue;
  }

  get copilotStates() {
    return [
      {
        text: this.intl.t('knowledge-hub.filterable-list.filters.copilot-state.enabled'),
        value: String(AiContentState.USED_BY_FIN),
      },
      {
        text: this.intl.t('knowledge-hub.filterable-list.filters.copilot-state.disabled'),
        value: String(AiContentState.NOT_USED_BY_FIN),
      },
    ];
  }

  get selectedCopilotState() {
    let copilotState = this.copilotStates.find(
      (state) => state.value === this.args.selectedCopilotStateValue,
    );
    return !copilotState?.value
      ? this.intl.t('knowledge-hub.filterable-list.filters.copilot-state.any-copilot-state')
      : copilotState.text;
  }

  @action removeFilter() {
    this.args.doSearch();
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::CopilotStateFilter': typeof CopilotStateFilter;
    'knowledge-hub/filterable-list/filters/copilot-state-filter': typeof CopilotStateFilter;
  }
}
