/* import __COLOCATED_TEMPLATE__ from './grouped-table-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { type TableColumn } from 'embercom/components/knowledge-hub/filterable-list/list';

interface Signature {
  Element: HTMLElement;

  Args: {
    columnsHash: Record<string, TableColumn>;
    valuePath: string;
    rowData: any;
    isSibling: boolean;
    hasSiblings: boolean;
    showDraggableIcon?: boolean;
    activeHoverRowId: string | null;
  };

  Blocks: {
    default: [
      {
        data: any;
        valuePath: string;
        isSibling: boolean;
        hasSiblings: boolean;
        showDraggableIcon?: boolean;
        activeHoverRowId?: string | null;
      },
    ];
  };
}

export default class GroupedTableCell extends Component<Signature> {
  get columnDefinition() {
    return this.args.columnsHash[this.args.valuePath];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::GroupedTableCell': typeof GroupedTableCell;
    'knowledge-hub/filterable-list/table/grouped-table-cell': typeof GroupedTableCell;
  }
}
