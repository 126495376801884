/* import __COLOCATED_TEMPLATE__ from './side-panel-accordion.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type KnowledgeHubEditorService from 'embercom/services/knowledge-hub-editor-service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Element: HTMLElement;
  Args: {
    icon?: InterfaceIconName;
    header: string;
    open: boolean;
    section: string;
  };
  Blocks: {
    default: [];
    customHeader: [];
  };
}

export default class SidePanelAccordion extends Component<Signature> {
  @tracked openSectionId: string | undefined;
  @service declare knowledgeHubEditorService: KnowledgeHubEditorService;

  constructor(
    owner: unknown,
    args: {
      header: string;
      open: boolean;
      section: string;
    },
  ) {
    super(owner, args);
    if (args.open) {
      this.openSectionId = 'section';
    }
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action
  onSectionDidOpen() {
    this.trackAnalytics('expanded');
  }

  @action
  onSectionDidClose() {
    this.trackAnalytics('collapsed');
  }

  trackAnalytics(action: string) {
    this.knowledgeHubEditorService.trackAnalyticsEvent(
      `${action}_${this.args.section}_section`,
      'knowledge_hub_content_editor_side_panel',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::SidePanelAccordion': typeof SidePanelAccordion;
    'knowledge-hub/content-editor/shared/side-panel-accordion': typeof SidePanelAccordion;
  }
}
