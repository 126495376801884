/* import __COLOCATED_TEMPLATE__ from './content-type.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';
import { type EntityType } from 'embercom/models/data/entity-types';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    contentType: EntityType;
  };
}

const ContentType = templateOnlyComponent<Signature>();
export default ContentType;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::ContentType': typeof ContentType;
    'knowledge-hub/filterable-list/table/cells/content-type': typeof ContentType;
  }
}
