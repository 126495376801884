/* import __COLOCATED_TEMPLATE__ from './primary-products.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import type Product from 'embercom/models/product';
import { CORE_STARTER_ID, CORE_CONVERT_ID } from 'embercom/lib/billing';

interface Args {
  primaryProducts: Array<Product>;
  isSalesforceContracted: boolean;
}
export default class PrimaryProductsComponent extends Component<Args> {
  get isSalesforceContracted(): boolean {
    return this.args.isSalesforceContracted;
  }

  /**
   * for VBP 2.X
   *  - show Starter for non Salesforce Contracted
   *  - hide Convert for non Salesforce Contracted
   */
  get primaryProducts(): Array<Product> {
    let products = this.args.primaryProducts;

    let hasCoreStarterProduct = products.some((product: Product) => product.id === CORE_STARTER_ID);

    if (hasCoreStarterProduct) {
      if (this.isSalesforceContracted) {
        products = products.filter((product) => product.id !== CORE_STARTER_ID);
      } else {
        products = products.filter((product) => product.id !== CORE_CONVERT_ID);
      }
    }

    return products;
  }

  get activePrimaryProducts(): Array<Product> {
    return this.primaryProducts.filter((product) => product.active);
  }

  get inactivePrimaryProducts(): Array<Product> {
    return this.primaryProducts.filter((product) => !product.active);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Home::PrimaryProducts': typeof PrimaryProductsComponent;
    'intershop/home/primary-products': typeof PrimaryProductsComponent;
  }
}
