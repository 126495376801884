/* import __COLOCATED_TEMPLATE__ from './search.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type KnowledgeHubApi from 'embercom/lib/knowledge-hub/list-api';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  searchTerm?: string;
  linkedUrls?: string[];
  setSearchTerm: (searchTerm?: string) => void;
  setLinkedUrls: (linkedUrls?: string[]) => void;
  listApi: KnowledgeHubApi;
  beforeSearch?: () => void;
}

const SEARCH_ANALYTICS_OBJECT = 'search';
const SEARCH_ANALYTICS_PLACE = 'knowledge_hub';

export default class Search extends Component<Args> {
  @service declare intercomEventService: $TSFixMe;

  @action
  async performSearchTransition(queryParams: any) {
    this.args.setSearchTerm(queryParams.search_term);
    this.args.setLinkedUrls(queryParams.url_partials);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'searched',
      object: SEARCH_ANALYTICS_OBJECT,
      place: SEARCH_ANALYTICS_PLACE,
    });
    this.args.beforeSearch?.();
    await this.args.listApi.search(queryParams.search_term, queryParams.url_partials);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Search': typeof Search;
    'knowledge-hub/search': typeof Search;
  }
}
