/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { productPageData } from 'embercom/lib/intershop/intershop';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Product from 'embercom/models/product';
import type CatalogApi from 'embercom/services/catalog-api';
import { taskFor } from 'ember-concurrency-ts';
import type IntershopService from 'embercom/services/intershop-service';

interface Args {
  product: Product;
  higherTierPlan: any;
}

export default class HeaderComponent extends Component<Args> {
  @service declare appService: any;
  @service declare customerService: any;
  @service declare catalogApi: CatalogApi;
  @service declare intershopService: IntershopService;

  get productData(): any {
    return productPageData(this.args.product.id);
  }

  get customerIsSalesManaged(): boolean {
    return this.customerService.isSalesManagedCustomer;
  }

  get canRequestFreeTrialViaTicketing(): boolean {
    return (
      this.appService.app.canSelfManageContract &&
      ((!this.args.product.isOnTrial && this.args.higherTierPlan) ||
        !this.args.product.billableCustomerPlan)
    );
  }

  @action requestFreeTrial() {
    let activePlan = this.args.product.billableCustomerPlan;
    let params = {
      app_id: this.appService.app.id,
      product_name: `${this.args.product.name} ${this.args.higherTierPlan.name}`,
      current_plan: activePlan
        ? `${this.args.product.name} ${activePlan.name}`
        : `No current plan on ${this.args.product.name}`,
      admin_id: this.appService.app.currentAdmin.id,
      admin_email: this.appService.app.currentAdmin.email,
    };
    taskFor(this.catalogApi.requestFreeTrial).perform(params);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::Header': typeof HeaderComponent;
    'intershop/products/header': typeof HeaderComponent;
  }
}
