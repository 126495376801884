/* import __COLOCATED_TEMPLATE__ from './static-seat-based.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import numericFormatter from 'embercom/lib/numeric-formatter';
import { type Metric } from 'embercom/models/data/pricing/metric-types';
import type Product from 'embercom/models/product';
import type Router from '@ember/routing/router-service';
import type Plan from 'embercom/models/plan';
import type IntershopService from 'embercom/services/intershop-service';
import { CORE_STARTER_BASE_ID } from 'embercom/lib/billing';

interface Args {
  plan: Plan;
  product: Product;
  pricingMetric: PricingMetric;
  onChange: (seats: number, metric: Metric) => {};
}

export default class StaticSeatBasedComponent extends Component<Args> {
  @service declare router: Router;
  @service customerService: any;
  @service declare intershopService: IntershopService;
  @tracked currentUsage;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.currentUsage = this.intershopService.billedUsage[this.args.pricingMetric.metric];
  }

  ROUTE = 'apps.app.intershop.products.product.plans.plan';

  get contractedSeats(): number {
    return this.intershopService.contractUsageLimits[this.args.pricingMetric.metric];
  }

  get overageSeats(): number {
    return this.currentUsage - this.contractedSeats;
  }

  get pricePerSeat(): string {
    return numericFormatter(this.args.pricingMetric.perUnitPrice / 100, 2, false);
  }

  get overlappingProductPlanName(): string {
    if (this.overlappingPlan.id === CORE_STARTER_BASE_ID) {
      return this.overlappingPlan.name;
    }
    return `${this.overlappingPlan.product.name} ${this.overlappingPlan.name}`;
  }

  get overlappingPlan(): Plan {
    let overlappingPlan = this.customerService
      .getPlansWithOverlappingPricingMetrics(this.args.plan)
      .map((id: number) =>
        this.customerService.plans.find((plan: Plan) => {
          return Number(plan.id) === id && !plan.product.addon;
        }),
      ).firstObject;

    return overlappingPlan;
  }

  @action
  transitionToProduct() {
    this.router.transitionTo(
      this.ROUTE,
      this.overlappingPlan.product.get('slug'),
      this.overlappingPlan.get('slug'),
    );
  }

  @action
  onChange(): void {
    this.args.onChange(this.currentUsage, this.args.pricingMetric.metric);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingMetrics::StaticSeatBased': typeof StaticSeatBasedComponent;
    'intershop/pricing/pricing-metrics/static-seat-based': typeof StaticSeatBasedComponent;
  }
}
