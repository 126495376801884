/* import __COLOCATED_TEMPLATE__ from './locale-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import type Store from '@ember-data/store';

interface Args {
  doSearch: (locale?: string) => void;
  selectedLocaleValue: string | null;
  activeFilter?: string;
  removeFilter: (locale?: string) => void;
  selectableLocales: Array<string>;
}

export default class LocaleFilter extends Component<Args> {
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };
  @service declare appService: any;
  @service declare store: Store;

  get isOpen() {
    return this.args.activeFilter === 'locale' && !this.args.selectedLocaleValue;
  }

  get app() {
    return this.appService.app;
  }

  get locales() {
    return [
      ...this.args.selectableLocales?.map((locale: string) => ({
        text: this.intl.languageNameFromCode(locale),
        value: locale,
      })),
    ].sort((a, b) => a.text.localeCompare(b.text));
  }

  get selectedLocaleName() {
    let locale = this.locales.find((locale) => locale.value === this.args.selectedLocaleValue);
    return !locale || !locale.value
      ? this.intl.t('knowledge-hub.filterable-list.filters.locales.any-languages')
      : this.intl.t('knowledge-hub.filterable-list.filters.locales.language-is', {
          language: locale.text,
        });
  }

  @action removeFilter() {
    this.args.doSearch();
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::LocaleFilter': typeof LocaleFilter;
    'knowledge-hub/filterable-list/filters/locale-filter': typeof LocaleFilter;
  }
}
