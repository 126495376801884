/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import type FileSource from 'embercom/models/content-service/file-source';
import type FileSourceContent from 'embercom/models/file-source-content';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type ContentImportService from 'embercom/services/content-import-service';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import { EntityType } from 'embercom/models/data/entity-types';

interface Args {
  activeContent: FileSourceContent;
  editActiveContent: () => void;
  showSidePanel: boolean;
  toggleSidePanel: () => void;
}

export default class Header extends Component<Args> {
  @service declare store: Store;
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service declare contentImportService: ContentImportService;
  @service declare permissionsService: any;

  @tracked showFileUploadModal = false;
  @tracked isSynced = false;

  fileSourceContent?: FileSourceContent;
  fileSource?: FileSource;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.contentImportService.subscribeToFileSourceRunStatusUpdates();
    this.contentImportService.fileSourceCompletionCallback = this.reloadActiveContent.bind(this);
    taskFor(this.loadFileSourceContent).perform();
  }

  @action async toggleFileUploadModal(state = false): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (e) {
      return;
    }

    this.showFileUploadModal = state;
    this.trackAnalyticsEvent('clicked', 'file_reupload_button');
  }

  @action handlePdfUploadBackButtonClick(): void {
    this.showFileUploadModal = false;
    this.trackAnalyticsEvent('clicked', 'file_reupload_back_button');
  }

  @action onFileReuploadSuccess(): void {
    this.args.activeContent.reload();
    this.showFileUploadModal = false;
    this.notificationsService.notifyConfirmation(
      this.intl.t('knowledge-hub.content-editor.pdf.re-upload.success-message'),
    );
  }

  @action reloadActiveContent(): void {
    this.args.activeContent.reload();
  }

  @dropTask
  *loadFileSource(): TaskGenerator<void> {
    try {
      if (!this.fileSourceContent?.sourceId) {
        return;
      }

      this.fileSource = yield this.store.findRecord(
        'content-service/file-source',
        this.fileSourceContent.sourceId,
      );
    } catch (err) {
      this.notifyNotFoundError();
    }
  }

  @dropTask
  *loadFileSourceContent(): TaskGenerator<void> {
    try {
      let result = yield this.store.findRecord('file-source-content', this.args.activeContent.id);

      this.fileSourceContent = result as FileSourceContent;

      this.isSynced = this.fileSourceContent?.sourceType !== EntityType.FileSource;

      if (!this.isSynced) {
        taskFor(this.loadFileSource).perform();
      }
    } catch (err) {
      this.notifyNotFoundError();
    }
  }

  private notifyNotFoundError(): void {
    this.notificationsService.notifyError(
      this.intl.t('knowledge-hub.content-editor.pdf.re-upload.failure-message.not-found'),
    );
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'knowledge_hub',
      context: 'all_content',
      place: 'pdf_viewer',
      ...metadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Pdf::View::Header': typeof Header;
    'knowledge-hub/content-editor/pdf/view/header': typeof Header;
  }
}
