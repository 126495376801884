/* import __COLOCATED_TEMPLATE__ from './article-id.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { type LocalizedKnowledgeContent } from 'embercom/objects/knowledge-hub/localized-knowledge-content';
import { EntityType } from 'embercom/models/data/entity-types';
import type ArticleContent from 'embercom/models/articles/article-content';

interface Args {
  activeContent: LocalizedKnowledgeContent;
}

export default class ArticleId extends Component<Args> {
  get articleId(): string | undefined {
    if (this.args.activeContent.entityType === EntityType.ArticleContent) {
      return (
        (this.args.activeContent.parentContent as ArticleContent).articleId ||
        (this.args.activeContent.parentContent as ArticleContent).id
      );
    }
    return undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::ArticleId': typeof ArticleId;
    'knowledge-hub/content-editor/shared/data-section/article-id': typeof ArticleId;
  }
}
