/* import __COLOCATED_TEMPLATE__ from './grouped-table-row.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type TableColumn } from 'embercom/components/knowledge-hub/filterable-list/list';
import { type Tree, type TreeItem } from 'embercom/objects/tree-list';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { localCopy } from 'tracked-toolbox';
import { type KnowledgeHubItem } from 'embercom/models/content-service/knowledge-hub-item';
import { modifier } from 'ember-modifier';
import { ItemPoint } from 'embercom/components/common/tree-list/tree-item';
import { EntityType } from 'embercom/models/data/entity-types';
import Folder from 'embercom/models/content-service/folder';

interface Signature {
  Element: HTMLElement;

  Args: {
    data: any;
    columnsHash: Record<string, TableColumn>;
    index: number;
    isSkeleton?: boolean;
    dataTestAttributeKey?: string;
    showAllSiblings?: boolean;
    treeItem?: TreeItem<KnowledgeHubItem>;
    removeContentWrapper?: (contentWrapperId: string) => void;
  };

  Blocks: {
    default: any;
  };
}

export default class GroupedTableRow extends Component<Signature> {
  @service declare appService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @tracked declare tree: Tree;
  @tracked activeHoverRowId = null;
  elementRef?: HTMLElement = undefined;
  itemPoint?: ItemPoint = undefined;

  @localCopy('args.showAllSiblings', false) isSiblingContentVisible = this.args.showAllSiblings;

  setElementRef = modifier(
    (element: Element) => {
      this.elementRef = element as HTMLElement;
    },
    { eager: false },
  );

  get isBeingDragged(): boolean {
    if (this.tree?.draggingItem && this.args.data.treeItem) {
      return this.tree.draggingItem === this.args.data.treeItem;
    }
    return false;
  }

  get isDraggable(): boolean {
    if (this.appService.app.canUseStandalone) {
      return false;
    }
    if (!this.knowledgeHubService.currentAdminCanManageContent) {
      return false;
    }
    let treeItem = this.args.data.treeItem as TreeItem<KnowledgeHubItem>;
    return treeItem?.dataObject?.isEditable ?? false;
  }

  get showDraggableIcon(): boolean {
    return (
      (this.isDraggable &&
        this.activeHoverRowId === this.args.data[this.args.dataTestAttributeKey || 'id']) ??
      false
    );
  }

  @action
  toggleSiblingsVisibility() {
    this.isSiblingContentVisible = !this.isSiblingContentVisible;
  }

  get showContents() {
    return this.isSiblingContentVisible && this.args.data.isMultilingual;
  }

  @action
  startDrag() {
    if (this.knowledgeHubService.currentAdminCanManageContent) {
      this.tree = this.knowledgeHubService.tree;
      if (this.args.data?.peekContentObject?.treeItem) {
        // Item is in the left-nav, so we need drag that tree item
        this.tree.draggingItem = this.args.data.peekContentObject.treeItem as TreeItem;
        this.tree.draggingItem.removeContentWrapperFromList = (_) => {
          this.args.removeContentWrapper?.(this.args.data.id);
        };
      } else if (this.args.data.treeItem) {
        // Item is in the table, so we need to drag the table tree item
        this.tree.draggingItem = this.args.data.treeItem as TreeItem;
        this.tree.draggingItem.removeContentWrapperFromList = this.args.removeContentWrapper;
      }
    }
  }

  get dataTreeItem(): TreeItem<KnowledgeHubItem> {
    return this.args.data?.peekContentObject?.treeItem || this.args.data.treeItem;
  }

  get dataObjectIfFolder(): Folder | undefined {
    if (this.isFolder && this.dataTreeItem.dataObject instanceof Folder) {
      return this.dataTreeItem.dataObject;
    }
    return undefined;
  }

  get grayedOut() {
    if (this.knowledgeHubService.tree?.draggingItem) {
      return !this.isFolder || this.dataObjectIfFolder?.isSynced;
    }
    return false;
  }

  @action
  endDrag() {
    if (this.knowledgeHubService.currentAdminCanManageContent) {
      this.tree = this.knowledgeHubService.tree;
      this.tree.draggingItem = undefined;
      this.tree.selectedDroppingPoint = undefined;
    }
  }

  get isFolder(): boolean {
    return this.args.data.entityType === EntityType.ContentLibraryFolder;
  }

  @action
  onDragOver(event: DragEvent) {
    this.tree = this.knowledgeHubService.tree;
    let item = this.dataTreeItem;
    if (this.isFolder && this.tree?.draggingItem !== item) {
      if (this.tree && item && this.elementRef) {
        this.itemPoint = this.createItemPoint(this.tree, item, this.elementRef);
        this.tree.setDroppingPoint(this.itemPoint);
      }
      event.preventDefault();
    }
  }

  get isBeingDraggedOver(): boolean {
    return (
      this.isFolder &&
      this.itemPoint === this.tree?.selectedDroppingPoint?.elementReference &&
      !!this.tree?.selectedDroppingPoint?.elementReference
    );
  }

  @action
  onDragEnter(event: DragEvent) {
    event.preventDefault();
  }

  @action
  onDrop() {
    this.tree = this.knowledgeHubService.tree;
    if (this.tree) {
      this.tree.dropItemOnSelectedDroppingPoint();
      this.tree.selectedDroppingPoint = undefined;
      this.itemPoint = undefined;
    }
  }

  @action
  onDragLeave(event: DragEvent) {
    if (this.elementRef) {
      let { top, bottom } = this.elementRef.getBoundingClientRect();
      if (event.clientY <= top || event.clientY >= bottom) {
        if (this.tree) {
          this.tree.selectedDroppingPoint = undefined;
          this.itemPoint = undefined;
        }
      }
    }
  }

  createItemPoint(tree: Tree, item: TreeItem<KnowledgeHubItem>, element: HTMLElement): ItemPoint {
    return new ItemPoint({
      element,
      item,
      tree,
      parent: item.parent,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::GroupedTableRow': typeof GroupedTableRow;
    'knowledge-hub/filterable-list/table/grouped-table-row': typeof GroupedTableRow;
  }
}
