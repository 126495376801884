/* import __COLOCATED_TEMPLATE__ from './folder-cell.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import type Folder from 'embercom/models/content-service/folder';
import { inject as service } from '@ember/service';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type RouterService from '@ember/routing/router-service';
import type { RouteInfoWithAttributes } from '@ember/routing/route-info';

interface Args {
  folderId: string;
}

export default class FolderCell extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare store: any;
  @service declare router: RouterService;
  get folder(): Folder | undefined {
    if (this.args.folderId) {
      return this.store.peekRecord(`content-service/folder`, this.args.folderId);
    }
    return undefined;
  }

  get isCurrentFolder() {
    if (!this.folder) {
      return false;
    }
    let currentRoute = this.router.currentRoute as RouteInfoWithAttributes;

    if (!currentRoute || !currentRoute.attributes) {
      return false;
    }

    return this.folder === currentRoute.attributes;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Table::Cells::FolderCell': typeof FolderCell;
    'knowledge-hub/filterable-list/table/cells/folder-cell': typeof FolderCell;
  }
}
