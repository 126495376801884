/* import __COLOCATED_TEMPLATE__ from './manage-source-dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import moment from 'moment-timezone';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { SyncBehavior } from 'embercom/models/knowledge-hub/sync-source-wrapper';
import { taskFor } from 'ember-concurrency-ts';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';
import ConnectSourceDropdownOption from './connect-source-dropdown-option';

import type SyncSourceWrapper from 'embercom/models/knowledge-hub/sync-source-wrapper';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import { type SourceRowActionOption, type SourceRowActionsGroupList } from '../sections/section';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { type ButtonType } from '@intercom/pulse/template-registry';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

export interface Signature {
  Args: {
    syncSource?: SyncSourceWrapper;
    type?: ButtonType;
    sectionDisabled?: boolean;
    actionButtonLabel?: string;
    actionOptions?: SourceRowActionsGroupList;
    includeIcon?: boolean;
    includeAddNew?: boolean;
    onAddSyncSource?: (sourceType: string) => void;
    onClickButton?: () => void;
  };
}

export default class ManageSourceDropdown extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare store: Store;

  @tracked sourceToRemove?: SyncSourceWrapper | null;
  @tracked sourceToEdit?: ContentImportSource | null;
  @tracked sourceEditWrapper?: SyncSourceWrapper | null;
  @tracked showRemoveSourceModal = false;
  @tracked showSourceSettingsModal = false;
  @tracked showBoxModal = false;
  @tracked showDocument360Modal = false;
  @tracked currentView: any;
  @tracked existingImportSource: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare openCenteredWindowService: $TSFixMe;

  @action async requestSourceSettings(syncSource: SyncSourceWrapper) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (error) {
      return;
    }
    this.sourceToEdit = await syncSource.show();
    this.sourceEditWrapper = syncSource;
    this.setShowSourceSettingsModal(true);
  }

  get isSectionDisabled(): boolean {
    return this.args.sectionDisabled ?? false;
  }

  get actionButtonLabel(): string {
    return (
      this.args.actionButtonLabel ??
      this.intl.t('knowledge-hub.overview.sections.syncs.rows.active.action.label')
    );
  }

  get icon(): InterfaceIconName | undefined {
    return this.args.includeIcon ? 'settings' : undefined;
  }

  @action onClickActionButton() {
    return this.args.onClickButton?.();
  }

  get activeSyncOptions(): SourceRowActionsGroupList {
    if (this.args.actionOptions) {
      return this.args.actionOptions;
    }
    let actions: SourceRowActionOption[] = [];
    let syncSource = this.args.syncSource as SyncSourceWrapper;
    if (syncSource.canResync) {
      actions.push({
        text: this.intl.t(
          'knowledge-hub.overview.sections.syncs.rows.active.action.options.resync',
        ),
        description: this.intl.t('knowledge-hub.overview.sections.syncs.rows.active.last-synced', {
          date: moment(syncSource.lastSyncedAt).fromNow(),
        }),
        icon: 'sync',
        onSelectItem: syncSource.isSyncing ? () => {} : () => taskFor(syncSource.resync).perform(),
        isDestructive: false,
        isDisabled: syncSource.isSyncing || syncSource.syncBehavior === SyncBehavior.API,
        tooltipText: syncSource.isSyncing
          ? this.fetchErrorTooltip('resync', syncSource)
          : undefined,
        tooltip: { isDelayed: false },
      });

      if (syncSource.sourceType === 'external_content') {
        let contentImportSource = syncSource.peekWebsiteSource;
        if (!contentImportSource || contentImportSource?.shouldShowOpenSettingsButton) {
          actions.push({
            text: this.intl.t(
              'knowledge-hub.overview.sections.syncs.rows.active.action.options.open-settings',
            ),
            icon: 'settings',
            onSelectItem: syncSource.isSyncing
              ? () => {}
              : () => this.requestSourceSettings(syncSource),
            isDestructive: false,
            isDisabled: syncSource.isSyncing || syncSource.syncBehavior === SyncBehavior.API,
            tooltipText: syncSource.isSyncing
              ? this.fetchErrorTooltip('settings', syncSource)
              : undefined,
            tooltip: { isDelayed: false },
          });
        }

        // Nolaneo – This is extremely useful for test apps. IMO I think we should reinstate this for everyone.
        if (this.appService.app?.canChangeContentImportState) {
          let labelKey = syncSource.isLive
            ? 'knowledge-hub.overview.sections.syncs.rows.active.action.options.disable'
            : 'knowledge-hub.overview.sections.syncs.rows.active.action.options.enable';

          let icon: 'pause' | 'play' = syncSource.isLive ? 'pause' : 'play';

          actions.push({
            text: this.intl.t(labelKey),
            icon,
            onSelectItem: async () => {
              this.notificationsService.notify(
                this.intl.t(
                  'knowledge-hub.overview.sections.syncs.rows.active.action.options.change-status-started',
                ),
              );

              await syncSource.update({
                status: syncSource.isLive ? 'deactivated' : 'active',
              });
            },
            isDestructive: false,
          });
        }
      }
      if (syncSource.sourceType === 'box') {
        actions.push({
          text: this.intl.t(
            'knowledge-hub.overview.sections.syncs.rows.active.action.options.open-settings',
          ),
          icon: 'settings',
          onSelectItem: syncSource.isSyncing
            ? () => {}
            : async () => {
                try {
                  this.reconfigureBoxSource();
                } catch (error) {
                  return;
                }
              },
          isDestructive: false,
          isDisabled: syncSource.isSyncing,
          tooltipText: syncSource.isSyncing
            ? this.fetchErrorTooltip('settings', syncSource)
            : undefined,
          tooltip: { isDelayed: false },
        });
      }
      if (syncSource.sourceType === 'box' || syncSource.sourceType === 'document360') {
        actions.push({
          text: this.intl.t(
            'knowledge-hub.overview.sections.syncs.rows.active.action.options.re-authenticate',
          ),
          icon: 'owner',
          onSelectItem: () => this.reauthenticateSource(),
          isDisabled: syncSource.isSyncing,
          tooltipText: syncSource.isSyncing
            ? this.intl.t(
                'knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-re-authenticate-tooltip',
              )
            : undefined,
          tooltip: { isDelayed: false },
          isDestructive: false,
        });
      }
    }

    if (syncSource.canImport) {
      actions.push({
        text: this.intl.t(
          'knowledge-hub.overview.sections.syncs.rows.active.action.options.import',
        ),
        icon: 'cloud-upload',
        onSelectItem: syncSource.isSyncing ? () => {} : () => syncSource.import(),
        isDestructive: false,
        isDisabled: syncSource.isSyncing,
        tooltipText: syncSource.isSyncing
          ? this.intl.t(
              'knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-import-tooltip',
            )
          : undefined,
        tooltip: { isDelayed: false },
      });
    }

    if (syncSource.canRemove) {
      actions.push({
        text: this.intl.t(
          'knowledge-hub.overview.sections.syncs.rows.active.action.options.remove',
        ),
        icon: 'trash',
        onSelectItem: syncSource.isSyncing ? () => {} : () => this.requestRemoveSource(syncSource),
        isDestructive: true,
        isDisabled: syncSource.isSyncing,
        tooltipText: syncSource.isSyncing
          ? this.intl.t(
              'knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-remove-tooltip',
            )
          : undefined,
        tooltip: { isDelayed: false },
      });
    }

    let options = [{ items: actions }];
    if (this.args.includeAddNew && syncSource.canAddNew) {
      let item: SourceRowActionOption = {
        text: '',
        description: '',
        icon: 'new',
        onSelectItem: () => this.args.onAddSyncSource?.(syncSource.sourceType),
        isDestructive: false,
      };

      if (syncSource.sourceType === 'zendesk') {
        item = {
          ...item,
          text: this.intl.t(
            'knowledge-hub.overview.sections.syncs.rows.active.action.options.new.zendesk.label',
          ),
          description: this.intl.t(
            'knowledge-hub.overview.sections.syncs.rows.active.action.options.new.zendesk.description',
          ),
          icon: 'add',
        };
      } else {
        item = {
          ...item,
          text: this.intl.t(
            'knowledge-hub.overview.sections.syncs.rows.active.action.options.connect',
            {
              type: this.intl.t(
                `knowledge-hub.overview.sections.syncs.types.${syncSource.sourceType}`,
              ),
            },
          ),
          component: ConnectSourceDropdownOption,
        };
      }

      options.push({ items: [item] });
    }
    return options;
  }

  setShowSourceSettingsModal(state = false) {
    this.showSourceSettingsModal = state;
  }

  @action async requestRemoveSource(syncSource: SyncSourceWrapper) {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (error) {
      return;
    }

    this.sourceToRemove = syncSource;
    this.setShowRemoveSourceModal(true);
  }

  @action closeRemoveModal() {
    this.setShowRemoveSourceModal(false);
    this.sourceToRemove = undefined;
  }

  @action async removeSource() {
    let sourceToRemove = this.sourceToRemove;
    if (!sourceToRemove) {
      return;
    }

    await sourceToRemove.remove();
    sourceToRemove = null;
    this.closeRemoveModal();
  }

  @action async reconfigureBoxSource() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (error) {
      return;
    }

    try {
      // Get the existing import source
      this.existingImportSource = await this.store.findRecord(
        'import-service/import-source',
        Number(this.args.syncSource?.sourceId),
      );

      if (!this.existingImportSource) {
        return;
      }

      // Show the Box item selector modal
      this.currentView = {
        id: 'box',
        title: this.intl.t('knowledge-hub.filterable-list.setup-modal.views.box-sync.title'),
        component: 'knowledge-hub/setup-modal/sync-modal/box',
        componentArgs: {
          isReconfiguring: true,
        },
      };
      this.showBoxModal = true;
    } catch (error) {
      return;
    }
  }

  @action async reauthenticateSource() {
    if (this.args.syncSource?.sourceType === 'box') {
      try {
        await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
      } catch (error) {
        return;
      }

      let importSource = await this.store.findRecord(
        'import-service/import-source',
        Number(this.args.syncSource?.sourceId),
      );

      if (!importSource) {
        return;
      }
      let url = this.knowledgeHubService.getOAuthURL(importSource.id);

      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      let window = this.openCenteredWindowService.setup(url, 800, 1280, 'Import');

      if (window) {
        window.focus();
        await this.waitForWindowClose(window);
      }

      await importSource.reload();

      if (importSource.hasCredentials) {
        if (this.args.syncSource?.resync) {
          await taskFor(this.args.syncSource.resync).perform();
        }
        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.sync-modal.box.re-authenticate-success'),
        );
      } else {
        this.notificationsService.notify(
          this.intl.t('knowledge-hub.sync-modal.box.re-authenticate-error'),
        );
      }
    } else if (this.args.syncSource?.sourceType === 'document360') {
      try {
        await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
      } catch (error) {
        return;
      }
      this.existingImportSource = await this.store.findRecord(
        'import-service/import-source',
        Number(this.args.syncSource?.sourceId),
      );

      this.currentView = {
        id: 'document360',
        title: this.intl.t('knowledge-hub.sync-modal.document360.title'),
        component: 'knowledge-hub/setup-modal/sync-modal/document360',
        componentArgs: {
          existingImportSource: this.existingImportSource,
          isReconfiguring: true,
        },
      };
      this.showDocument360Modal = true;
    } else {
      this.notificationsService.notify(
        this.intl.t('knowledge-hub.sync-modal.document360.re-authenticate-error', {
          source: this.args.syncSource?.sourceType,
        }),
      );
    }
  }

  private waitForWindowClose(window: Window): Promise<void> {
    return new Promise((resolve) => {
      let checkInterval = setInterval(() => {
        if (window.closed) {
          clearInterval(checkInterval);
          resolve();
        }
      }, 200);
    });
  }

  @action closeSourceSettingsModal() {
    this.setShowSourceSettingsModal(false);
    this.sourceToEdit = undefined;
  }

  setShowRemoveSourceModal(state = false) {
    this.showRemoveSourceModal = state;
  }

  private fetchErrorTooltip(type: 'resync' | 'settings', syncSource: SyncSourceWrapper) {
    if (syncSource.syncBehavior === SyncBehavior.API) {
      return this.intl.t(
        `knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-${type}-tooltip-api`,
      );
    }
    return this.intl.t(
      `knowledge-hub.overview.sections.syncs.rows.active.action.options.disabled-${type}-tooltip`,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Shared::ManageSourceDropdown': typeof ManageSourceDropdown;
    'knowledge-hub/overview/shared/manage-source-dropdown': typeof ManageSourceDropdown;
  }
}
