/* import __COLOCATED_TEMPLATE__ from './chatbot-state-filter.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { AiContentState } from 'embercom/lib/ai-content-library/constants';

interface Args {
  doSearch: (chatbotState?: string) => void;
  selectedChatbotStateValue?: string;
  activeFilter?: string;
  removeFilter: (chatbotState?: string) => void;
}

export default class ChatbotStateFilter extends Component<Args> {
  @service declare intl: IntlService;

  get isOpen() {
    return this.args.activeFilter === 'chatbotState' && !this.args.selectedChatbotStateValue;
  }

  get chatbotStates() {
    return [
      {
        text: this.intl.t('knowledge-hub.filterable-list.filters.chatbot-state.enabled'),
        value: String(AiContentState.USED_BY_FIN),
      },
      {
        text: this.intl.t('knowledge-hub.filterable-list.filters.chatbot-state.disabled'),
        value: String(AiContentState.NOT_USED_BY_FIN),
      },
    ];
  }

  get selectedChatbotState() {
    let chatbotState = this.chatbotStates.find(
      (state) => state.value === this.args.selectedChatbotStateValue,
    );
    return !chatbotState?.value
      ? this.intl.t('knowledge-hub.filterable-list.filters.chatbot-state.any-chatbot-state')
      : chatbotState.text;
  }

  @action removeFilter() {
    this.args.doSearch();
    this.args.removeFilter();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::ChatbotStateFilter': typeof ChatbotStateFilter;
    'knowledge-hub/filterable-list/filters/chatbot-state-filter': typeof ChatbotStateFilter;
  }
}
