/* import __COLOCATED_TEMPLATE__ from './created-by.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  createdBy?: string;
  doSearch: (createdBy?: string) => void;
  activeFilter?: string;
  removeFilter: () => void;
}

const CreatedBy = templateOnlyComponent<Args>();
export default CreatedBy;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::Filters::CreatedBy': typeof CreatedBy;
    'knowledge-hub/filterable-list/filters/created-by': typeof CreatedBy;
  }
}
