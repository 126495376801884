/* import __COLOCATED_TEMPLATE__ from './stats-body.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type StatisticKeyName } from 'embercom/models/data/outbound/types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import type StatsParameters from 'embercom/objects/stats-system/stats-parameters';
import { makeStatsParametersForSupportContent } from 'embercom/helpers/stats-system/stats-parameters';
import { type ActiveContent } from 'embercom/lib/knowledge-hub/constants';
import { STATS_BODY_COMPONENTS } from 'embercom/lib/knowledge-hub/constants';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    statKeyName: StatisticKeyName;
    activeContent: ActiveContent;
  };
}

export default class StatsBody extends Component<Signature> {
  @service declare appService: any;

  @tracked range: $TSFixMe;

  componentMaps = STATS_BODY_COMPONENTS;

  get statsParameters(): StatsParameters {
    return makeStatsParametersForSupportContent(
      this.args.activeContent,
      this.range,
      this.appService.app.timezone,
    );
  }

  @action onUpdateDateRange(range: $TSFixMe) {
    this.range = range;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::StatsBody': typeof StatsBody;
    'knowledge-hub/content-editor/shared/stats-body': typeof StatsBody;
  }
}
