/* import __COLOCATED_TEMPLATE__ from './fixed.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import type PricingMetric from 'embercom/lib/purchase/pricing-metric';
import type Plan from 'embercom/models/plan';

interface Args {
  plan: Plan;
  pricingMetric: PricingMetric;
}

export default class FixedComponent extends Component<Args> {
  get price(): number {
    return this.args.pricingMetric.price / 100;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingMetrics::Fixed': typeof FixedComponent;
    'intershop/pricing/pricing-metrics/fixed': typeof FixedComponent;
  }
}
