/* import __COLOCATED_TEMPLATE__ from './product-card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  productCardData,
  excludedPlansNonSalesforceContracted,
  excludedPlansSalesforceContracted,
} from 'embercom/lib/intershop/intershop';
import type Product from 'embercom/models/product';

interface Args {
  product: Product;
}

const PRODUCT_ROUTE = 'apps.app.intershop.products.product';

export default class ProductCardComponent extends Component<Args> {
  @service declare router: RouterService;
  @service declare customerService: any;
  @service declare appService: any;

  get productRoute() {
    return PRODUCT_ROUTE;
  }

  get pricingModelIdentifier() {
    return this.customerService.currentPricingModelIdentifier;
  }

  get excludedPlans() {
    return this.appService.app.isSalesforceContracted
      ? excludedPlansSalesforceContracted(this.pricingModelIdentifier)
      : excludedPlansNonSalesforceContracted(this.pricingModelIdentifier);
  }

  get productDetails(): any {
    return productCardData(this.args.product.id);
  }

  get plans() {
    // filter out excluded plans from plan cards
    return this.args.product.plans.filter((plan) => !this.excludedPlans.includes(plan.id));
  }

  get stampTextKey(): string | null {
    if (!this.args.product.billableCustomerPlan) {
      return 'intershop.try_free';
    }

    let plansLength = this.plans.length;
    let billablePlanTierId = this.args.product.billableCustomerPlan.tierId;
    let onHighestTier = billablePlanTierId === plansLength;
    let nextTier = this.plans.objectAt(billablePlanTierId);

    if (
      this.args.product.hasOnePlan ||
      this.args.product.isPostTrial ||
      onHighestTier ||
      !nextTier
    ) {
      return null;
    }

    return `intershop.try_${nextTier.name.toLowerCase()}_plan_free`;
  }

  @action
  transitionToProduct(): void {
    this.router.transitionTo(PRODUCT_ROUTE, this.args.product.slug);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Home::ProductCard': typeof ProductCardComponent;
    'intershop/home/product-card': typeof ProductCardComponent;
  }
}
