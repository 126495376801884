/* import __COLOCATED_TEMPLATE__ from './select-content-type.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type IntlService from 'ember-intl/services/intl';
import {
  AI_AGENT_ENTITY_TYPES,
  AI_COPILOT_ENTITY_TYPES,
  FinAIType,
} from 'embercom/lib/knowledge-hub/constants';
import { EntityType } from 'embercom/models/data/entity-types';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type { ViewId } from '../../setup-modal';
import type Store from '@ember-data/store';
import type ArticleGroup from 'embercom/models/articles/article-group';
import type ConversationExtractionService from 'embercom/services/conversation-extraction-service';
import type RouterService from '@ember/routing/router-service';

interface Args {
  modal: any;
  title: string;
  onNavigationBack: () => void;
  onNavigationNext: (viewId: ViewId) => void;
  showBackButton: boolean;
  onModalClose: () => void;
  context?: {
    folder?: string;
    entityTypes?: EntityType[];
    aiTypeFilter?: FinAIType;
    includeNonKnowledgeBaseContent?: boolean;
    openInNewTab?: boolean;
    openInContext?: boolean;
  };
}

const CONTENT_ENTITY_TYPES = [
  EntityType.ArticleContent,
  EntityType.Answer,
  EntityType.InternalArticle,
  EntityType.ContentSnippet,
];

const STANDALONE_CONTENT_ENTITY_TYPES = [EntityType.Answer, EntityType.ContentSnippet];

const SYNC_ENTITY_TYPES = [
  EntityType.ArticleContent,
  EntityType.InternalArticle,
  EntityType.ExternalContent,
  EntityType.FileSourceContent,
];

export default class SelectContentType extends Component<Args> {
  @service declare router: RouterService;
  @service declare intercomEventService: $TSFixMe;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare permissionsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;
  @service declare appService: { app: $TSFixMe };
  @service declare conversationExtractionService: ConversationExtractionService;

  get conversationExtractionSettings() {
    return this.conversationExtractionService.conversationExtractionSettings;
  }

  get contentEntityTypes() {
    let entityTypesToDisplay = this.appService.app.canUseStandalone
      ? STANDALONE_CONTENT_ENTITY_TYPES
      : CONTENT_ENTITY_TYPES;
    return this.filterDisplayableEntityTypes(entityTypesToDisplay);
  }

  get syncEntityTypes() {
    let displayableValues = this.filterDisplayableEntityTypes(SYNC_ENTITY_TYPES);
    if (this.appService.app.hasStandalonePlatform('salesforce')) {
      return displayableValues.reject((entityType) => entityType === EntityType.ArticleContent);
    }
    return displayableValues;
  }

  get showConversationExtractionTile() {
    if (this.appService.app.canUseStandalone) {
      return false;
    }
    return !this.conversationExtractionSettings?.enabled;
  }

  private filterDisplayableEntityTypes(entityTypesToDisplay: EntityType[]) {
    let entityTypes: EntityType[] | null = null;
    if (this.args.context?.aiTypeFilter === FinAIType.COPILOT) {
      entityTypes = AI_COPILOT_ENTITY_TYPES;
    } else if (
      this.args.context?.aiTypeFilter === FinAIType.AGENT ||
      this.appService.app.canUseStandalone
    ) {
      entityTypes = AI_AGENT_ENTITY_TYPES;
    } else if (this.args.context?.entityTypes) {
      entityTypes = this.args.context?.entityTypes;
    }

    if (entityTypes) {
      entityTypesToDisplay = entityTypesToDisplay.filter((entityType) =>
        entityTypes?.includes(entityType),
      );
    }
    if (!this.args.context?.includeNonKnowledgeBaseContent) {
      entityTypesToDisplay = entityTypesToDisplay.filter(
        (entityType) => entityType !== EntityType.Answer,
      );
    }
    return entityTypesToDisplay;
  }

  get contentTiles() {
    let localizationPrefix = 'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles';
    return this.contentEntityTypes.map((entityType) => {
      return {
        id: `${entityType}`,
        title: this.intl.t(`${localizationPrefix}.${objectNames[entityType]}.title`),
        entityType,
        description: this.appService.app.canUseStandalone
          ? this.intl.t(`${localizationPrefix}.${objectNames[entityType]}.description-ai-agent`)
          : this.intl.t(`${localizationPrefix}.${objectNames[entityType]}.description`),
      };
    });
  }

  get importTiles() {
    let tiles = [
      {
        id: 'zendesk',
        viewId: 'zendesk-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.zendesk.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/zendesk.png'),
        entityType: EntityType.ArticleContent,
      },
      {
        id: 'guru',
        viewId: 'guru-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.guru.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/guru.png'),
        entityType: EntityType.InternalArticle,
      },
      {
        id: 'notion',
        viewId: 'notion-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.notion.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/notion.png'),
        entityType: EntityType.InternalArticle,
      },
      {
        id: 'confluence',
        viewId: 'confluence-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.confluence.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/confluence.png'),
        entityType: EntityType.InternalArticle,
      },
      {
        id: 'external-content',
        viewId: 'external-content',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.external-content.title',
        ),
        entityType: EntityType.ExternalContent,
      },
      {
        id: 'file-upload',
        viewId: 'file-upload',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.file.title',
        ),
        entityType: EntityType.FileSourceContent,
      },
    ].filter((tile) => this.syncEntityTypes.includes(tile.entityType));
    if (this.appService.app.canUseIntegrationAppForSalesforce) {
      tiles.push({
        id: 'salesforce_knowledge',
        viewId: 'salesforce-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.salesforce.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/salesforce.png'),
        entityType: EntityType.ExternalContent,
      });
    }
    if (this.appService.app.canUseIntegrationAppForBox) {
      tiles.push({
        id: 'box',
        viewId: 'box-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.box.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/box.png'),
        entityType: EntityType.ExternalContent,
      });
    }
    if (this.appService.app.canUseDoc360Integration) {
      tiles.push({
        id: 'document360',
        viewId: 'document360-sync',
        title: this.intl.t(
          'knowledge-hub.filterable-list.setup-modal.views.select-content.tiles.document360.title',
        ),
        sourceIconUrl: assetUrl('/assets/images/knowledge-hub/icons/document360.png'),
        entityType: EntityType.ExternalContent,
      });
    }
    return tiles;
  }

  @action async navigateToConversationExtraction() {
    await this.navigateToView('conversation-extraction', EntityType.ContentSnippet);
  }

  @action async navigateToView(viewId: ViewId, entityType: EntityType) {
    try {
      await this.knowledgeHubService.ensurePermissions(entityType);
    } catch (error) {
      this.args.onModalClose();
      return;
    }
    this.args.onNavigationNext(viewId);
  }

  @action async createContent(entityType: EntityType) {
    let collections = (await this.store.findAll('articles/article-group'))
      .toArray()
      .filter((el: ArticleGroup) => el.id !== 'home');

    if (entityType === EntityType.ArticleContent && collections.length === 0) {
      this.args.onNavigationNext('set-up-help-center');
      return;
    }
    this.knowledgeHubService.createContent(
      entityType,
      { folder_id: this.args.context?.folder },
      this.args.context?.openInNewTab,
      this.args.context?.openInContext,
    );
    this.args.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SelectContentType': typeof SelectContentType;
    'knowledge-hub/setup-modal/select-content-type': typeof SelectContentType;
  }
}
