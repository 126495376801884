/* import __COLOCATED_TEMPLATE__ from './nav-list-item.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import Folder from 'embercom/models/content-service/folder';
import { type TreeItem } from 'embercom/objects/tree-list';
import type IntlService from 'embercom/services/intl';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import FolderMembership from 'embercom/models/content-service/folder-membership';
import type RouterService from '@ember/routing/router-service';
import { type RouteInfoWithAttributes } from '@ember/routing/route-info';
import type Model from '@ember-data/model';
import { action } from '@ember/object';

interface Args {
  item: TreeItem<Folder | FolderMembership | undefined>;
  routeAfterDelete: string;
}

export default class NavListItem extends Component<Args> {
  @service intl!: IntlService;
  @service notificationsService: any;
  @service appService: any;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intercomEventService: any;
  @service declare router: RouterService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  get folder(): Folder | undefined {
    if (this.args.item.dataObject instanceof Folder) {
      return this.args.item.dataObject;
    }
    return undefined;
  }

  get folderItem(): TreeItem<Folder> | undefined {
    if (this.args.item.dataObject instanceof Folder) {
      return this.args.item as TreeItem<Folder>;
    }
    return undefined;
  }

  get folderMembership(): FolderMembership | undefined {
    if (this.args.item.dataObject instanceof FolderMembership) {
      return this.args.item.dataObject;
    }
    return undefined;
  }

  get isActive() {
    return this.args.item.isActive || (this.args.item.parent.isRoot && this.isOnAllContentRoute);
  }

  get isOnAllContentRoute(): boolean {
    return this.router.currentRouteName === 'apps.app.knowledge-hub.all-content';
  }

  @action
  toggleIsExpanded(event: MouseEvent) {
    event.preventDefault();

    let currentRoute = this.router.currentRoute as RouteInfoWithAttributes;
    let activeModel = currentRoute.attributes as Model;
    if (activeModel.id !== this.folder?.id) {
      return undefined;
    }
    this.args.item.isExpanded = !this.args.item.isExpanded;

    this.intercomEventService.trackAnalyticsEvent({
      action: this.args.item.isExpanded ? 'expanded' : 'collapsed',
      object: this.args.item.dataObject,
      place: 'knowledge-hub',
      section: 'nav-list-folder',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Folders::Tree::NavListItem': typeof NavListItem;
    'knowledge-hub/folders/tree/nav-list-item': typeof NavListItem;
  }
}
