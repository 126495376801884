/* import __COLOCATED_TEMPLATE__ from './usage-selector.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { type Block } from 'embercom/models/data/pricing/tier-types';
import { FIXED_DISCOUNT_OFFERED } from 'embercom/lib/intershop/intershop';

interface Args {
  metric: Metric;
  blocks: Array<Block>;
  onChange: (index: number, metric: Metric, discountOffered: number | undefined) => {};
  isPlanInSubscription: boolean;
  isPlanOnTrial: boolean;
  hasActivePlanOnProduct: boolean;
  showTierSelector: boolean;
}

export default class UsageSelectorComponent extends Component<Args> {
  @tracked selectedIndex = 0;
  @tracked inputValue: number = this.blocks[this.selectedIndex].ending_unit;

  @action
  onInputChange({ target }: any): void {
    let value = Number(target.value);

    let closest = this.blocks
      .map((b) => b.ending_unit)
      .reduce(function (prev, curr) {
        return Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev;
      });

    let block = this.blocks.find((block) => block.ending_unit === closest)!;
    let index = this.blocks.indexOf(block);

    this.updateIndex(index);
  }

  @action
  onSliderChange(index: number): void {
    this.updateIndex(index);
  }

  @action
  onTierSelectorChange(index: number): void {
    this.updateIndex(index);
  }

  updateIndex(index: number): void {
    this.selectedIndex = index;
    this.inputValue = this.blocks[this.selectedIndex].ending_unit;
    // in theory the first object should be the contracted amount
    let costDifference = this.args.isPlanInSubscription
      ? this.blocks[this.selectedIndex].cumulative_price - this.blocks[0].cumulative_price
      : 0;
    let discountOfferedOnCostDiff = Math.floor((costDifference / 100.0) * FIXED_DISCOUNT_OFFERED);
    this.args.onChange(index, this.args.metric, discountOfferedOnCostDiff);
  }

  get blocks(): Array<Block> {
    return this.args.blocks;
  }

  get totalBlocks(): number {
    return this.blocks.length;
  }

  get rangeMin(): number {
    return this.blocks.firstObject?.ending_unit ?? 0;
  }

  get rangeMax(): number {
    return this.blocks.lastObject?.ending_unit ?? 0;
  }

  get isResolutionsMetric(): boolean {
    return this.args.metric === Metric.resolutions;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Pricing::PricingMetrics::UsageSelector::UsageSelector': typeof UsageSelectorComponent;
    'intershop/pricing/pricing-metrics/usage-selector/usage-selector': typeof UsageSelectorComponent;
  }
}
