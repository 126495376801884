/* import __COLOCATED_TEMPLATE__ from './author.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Admin from 'embercom/models/admin';
import type KnowledgeHubDrawerEditorService from 'embercom/services/knowledge-hub-drawer-editor-service';
import { EditorMode } from '../../drawer-editor';

interface Args {
  author?: Admin;
  onSelectAuthor: (author: Admin) => void;
}

export default class Author extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;
  @service declare knowledgeHubDrawerEditorService: KnowledgeHubDrawerEditorService;

  get app() {
    return this.appService.app;
  }

  get isEditable() {
    return (
      this.router.currentRouteName === 'apps.app.knowledge-hub.edit' ||
      this.knowledgeHubDrawerEditorService.editorMode === EditorMode.EDIT
    );
  }

  @action
  updateAuthor(authorId: string) {
    let selectedAuthor = this.app.admins.find((admin: { id: string }) => admin.id === authorId);
    this.args.onSelectAuthor(selectedAuthor);
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::Author': typeof Author;
    'knowledge-hub/content-editor/shared/data-section/author': typeof Author;
  }
}
