/* import __COLOCATED_TEMPLATE__ from './card.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import type Product from 'embercom/models/product';
import type Plan from 'embercom/models/plan';
import { inject as service } from '@ember/service';
import {
  type RecommendationCard,
  recommendationCardData,
} from 'embercom/lib/intershop/intershop-recommendations';
import type IntershopService from 'embercom/services/intershop-service';

interface Args {
  productId: number;
  planId: number;
}

export default class CardComponent extends Component<Args> {
  @service declare appService: any;
  @service declare intershopService: IntershopService;

  get product(): Product {
    return this.appService.app.products.findBy('id', this.args.productId);
  }

  get plan(): Plan | undefined {
    return this.product.plans.find((plan) => plan.idAsNumber === this.args.planId);
  }

  get recommendationDetails(): RecommendationCard {
    return recommendationCardData(this.args.planId);
  }

  get linkToRoute(): string {
    if (this.product.addon) {
      return 'apps.app.intershop.add-ons.add-on';
    }
    return this.intershopService.canAccessPricing
      ? 'apps.app.intershop.products.product.plans.plan'
      : 'apps.app.intershop.products.product';
  }

  get linkToModels(): any {
    if (this.product.addon) {
      return [this.product.slug];
    }
    return this.intershopService.canAccessPricing
      ? [this.product.slug, this.plan?.slug]
      : [this.product.slug];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Recommendations::Card': typeof CardComponent;
    'intershop/recommendations/card': typeof CardComponent;
  }
}
