/* import __COLOCATED_TEMPLATE__ from './selector.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import type Admin from 'embercom/models/admin';

interface Args {
  selectedAuthorId: string;
  admins: Admin[];
  onSelectAuthor: (authorId: string) => void;
}

export default class Selector extends Component<Args> {
  get availableOptions() {
    return this.args.admins.map((admin) => {
      return {
        value: admin.id,
        text: admin.name,
        author: admin,
        component:
          'knowledge-hub/content-editor/shared/data-section/author-selector/selector-option',
      };
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ContentEditor::Shared::DataSection::AuthorSelector::Selector': typeof Selector;
    'knowledge-hub/content-editor/shared/data-section/author-selector/selector': typeof Selector;
  }
}
