/* import __COLOCATED_TEMPLATE__ from './box.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type ImportSource from 'embercom/models/import-service/import-source';
import { type SyncSteps } from 'embercom/components/knowledge-hub/filterable-list';
import { SourceType, SyncBehaviorType } from 'embercom/models/import-service/import-source';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import { get } from 'embercom/lib/ajax';

interface Args {
  modal: any;
  title?: string;
  onNavigationBack?: () => void;
  onNavigationNext?: (viewId?: string) => void;
  showBackButton?: boolean;
  onModalClose: () => void;
  setSyncStep: (step: SyncSteps) => void;
  connectionType: 'box';
  existingImportSource?: ImportSource;
  isReconfiguring?: boolean;
}

export default class BoxSyncModal extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare openCenteredWindowService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare intercomEventService: any;
  @service declare appService: any;

  @tracked importSource: ImportSource | undefined;
  @tracked isConnecting = false;
  @tracked syncBehavior: SyncBehaviorType = SyncBehaviorType.Sync;
  @tracked isLoadingOauth = true;
  @tracked credentials: any;
  @tracked selectedItems: any[] = [];
  @tracked items: any[] = [];
  @tracked allowedFileTypes: string[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    // check if we have an existing import source (reconfigure)
    if (this.args.existingImportSource) {
      this.importSource = this.args.existingImportSource;
    }
    taskFor(this.startOauthFlow).perform();
  }

  @action
  async initiateConnection() {
    if (this.isConnecting) {
      return;
    }

    this.isConnecting = true;
    try {
      await this.startSync();
      this.args.onModalClose();
    } catch (error) {
      this.isConnecting = false;
      throw error;
    }
  }

  @task({ drop: true })
  *startOauthFlow(): TaskGenerator<void> {
    // for reconfigure, we already have an import source
    if (!this.importSource) {
      this.importSource = yield this.knowledgeHubService.createImportSource({
        sourceType: SourceType.Box,
      });
    }

    if (!this.importSource) {
      return;
    }
    if (!this.args.existingImportSource) {
      let url = this.knowledgeHubService.getOAuthURL(this.importSource.id);

      // eslint-disable-next-line @intercom/intercom/no-bare-strings
      let window = this.openCenteredWindowService.setup(url, 800, 1280, 'Import');
      if (window) {
        window.focus();
        yield this.waitForWindowClose(window);
      }

      yield this.importSource.reload();
    }

    if (this.importSource.hasCredentials || this.args.existingImportSource) {
      yield this.fetchFolderTree();
    }

    if (this.importSource.context?.selectedItems) {
      this.selectedItems = this.importSource.context.selectedItems;
    }

    this.allowedFileTypes = this.importSource?.fileTypes ?? [];

    this.isLoadingOauth = false;

    if (!this.importSource.hasCredentials && !this.args.existingImportSource) {
      this.args.onModalClose();
      yield this.importSource.remove({ notify: false });
    }
  }

  async fetchFolderTree() {
    try {
      let response = await get('/ember/import_service/import_sources/box/folder_tree', {
        app_id: this.appService.app.id,
        import_source_id: this.importSource?.id,
      });
      this.items = response.items;
    } catch (error) {
      console.error('Error fetching folder tree:', error);
    }
  }

  private waitForWindowClose(window: any): Promise<void> {
    return new Promise((resolve) => {
      let checkInterval = setInterval(() => {
        if (window.closed) {
          clearInterval(checkInterval);
          resolve();
        }
      }, 200);
    });
  }

  get syncDisabled() {
    return !this.importSource;
  }

  @action
  async startSync() {
    if (!this.importSource) {
      return;
    }
    await this.knowledgeHubService.updateImportSourceWithParam(this.importSource.id, {
      selected_items: this.selectedItems,
    });

    await this.knowledgeHubService.startImport(this.importSource.id, this.syncBehavior);

    let notificationMessage =
      this.syncBehavior === SyncBehaviorType.Sync
        ? this.intl.t(`knowledge-hub.sync-modal.box.sync-started`)
        : this.intl.t(`knowledge-hub.sync-modal.box.import-started`);

    this.knowledgeHubService.notifySourceStateChangeConfirmation(
      notificationMessage,
      this.importSource.id,
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'import-content',
      object: `import-content.box`,
      section: `knowledge-hub.new-content.box`,
      place: 'knowledge-hub.new-content',
    });
  }

  @action
  async cancelSync() {
    // Only delete the source if it's a new import (not reconfiguring)
    if (this.importSource && !this.args.existingImportSource) {
      await this.importSource.remove({ notify: false });
    }
    this.args.onModalClose();
  }

  @action
  onSelectionChange(selectedSet: any[]) {
    this.selectedItems = selectedSet;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::Box': typeof BoxSyncModal;
    'knowledge-hub/setup-modal/sync-modal/box': typeof BoxSyncModal;
  }
}
