/* import __COLOCATED_TEMPLATE__ from './product.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import {
  MESSAGES_ID,
  INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
  CORE_STARTER_ID,
} from 'embercom/lib/billing';
import {
  PRODUCT_FEATURES,
  excludedPlansNonSalesforceContracted,
  excludedPlansSalesforceContracted,
} from 'embercom/lib/intershop/intershop';
import { inject as service } from '@ember/service';
import { keepLatestTask } from 'ember-concurrency-decorators';
import type Product from 'embercom/models/product';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import type Plan from 'embercom/models/plan';

interface Args {
  product: Product;
  plan: Plan;
}

export default class ProductComponent extends Component<Args> {
  @service declare customerService: any;
  @service declare intl: IntlService;
  @service declare appService: any;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.getPrices).perform();
  }

  hideBillingPricingMetricsIds = [MESSAGES_ID, CORE_STARTER_ID];

  get excludedPlans(): string[] {
    return this.appService.app.isSalesforceContracted
      ? excludedPlansSalesforceContracted(this.pricingModelIdentifier)
      : excludedPlansNonSalesforceContracted(this.pricingModelIdentifier);
  }

  get showBillingPricingMetrics(): boolean {
    return !this.hideBillingPricingMetricsIds.includes(this.args.product.id);
  }

  get billableCustomerPlan(): Plan | undefined {
    return this.args.product.billableCustomerPlan;
  }

  get higherTierPlan(): Plan | undefined {
    if (!this.billableCustomerPlan) {
      return this.plans.firstObject;
    }
    return this.args.product.plans.objectAt(this.billableCustomerPlan.tierId);
  }

  get productFeatureList(): any {
    return PRODUCT_FEATURES[this.args.product.id];
  }

  get colSpan(): string {
    let planLength = this.plans.length;
    if (planLength > 2) {
      return 'col-span-4';
    }
    if (planLength === 2) {
      return 'col-span-6';
    }
    return 'col-start-3 col-span-8';
  }

  get pricingModelIdentifier(): string {
    return this.customerService.currentPricingModelIdentifier;
  }

  get plans(): Plan[] {
    // filter out excluded plans from plan cards
    return this.args.product.plans.filter((plan) => !this.excludedPlans.includes(plan.id));
  }

  get metrics(): any[] {
    if (!this.showBillingPricingMetrics) {
      return this.productFeatureList.metrics;
    }

    return this.productFeatureList.metrics.map((intershopMetric: any, index: number) => {
      return {
        ...intershopMetric,
        includedInPlans: intershopMetric.includedInPlans.map((includedInPlans: any) => {
          let planPricing = this.customerService
            .getPriceFromPlanIds([parseInt(includedInPlans.id, 10)], this.pricingModelIdentifier)
            ?.getBreakdownInfo();
          let breakdown = planPricing?.firstObject.breakdown;
          if (!planPricing || !breakdown.objectAt(index)) {
            return includedInPlans;
          }
          let metric = breakdown.objectAt(index).metricName.replace(/ /g, '_');
          return {
            ...(includedInPlans.includes && { includes: includedInPlans.includes }),
            id: includedInPlans.id,
            blocksize: parseInt(breakdown.objectAt(index).blockSize.replace(/[^a-z0-9]/gi, ''), 10),
            price: parseInt(
              breakdown.objectAt(index).additionaCostPerExtraBlock.replace(/[^a-z0-9]/gi, ''),
              10,
            ),
            translationPath: breakdown.objectAt(index).seatBased
              ? `intershop.product_plan.pricing_metrics.pricing.seat`
              : `intershop.product_plan.pricing_metrics.pricing.${metric}`,
          };
        }),
      };
    });
  }

  get planNames(): string[] {
    return this.plans.map((plan) => plan.name);
  }

  @keepLatestTask *getPrices() {
    let planIds = this.plans.map((plan) => plan.idAsNumber);
    let pricesNotLoaded = planIds.filter((id) => {
      return !this.customerService.isPriceLoaded([id], this.pricingModelIdentifier);
    });
    if (pricesNotLoaded.length) {
      yield this.customerService.bulkLoadPrices(
        pricesNotLoaded.map((id) => ({
          planIds: [id],
          includeTiers: INCLUDED_TIERS_FROM_PRICING_ENDPOINT,
          source: 'intershop-product-route',
        })),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Products::Product': typeof ProductComponent;
    'intershop/products/product': typeof ProductComponent;
  }
}
