/* import __COLOCATED_TEMPLATE__ from './public-articles.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { type OverviewSectionArgs } from '../../overview';

import templateOnlyComponent from '@ember/component/template-only';

const PublicArticles = templateOnlyComponent<OverviewSectionArgs>();
export default PublicArticles;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Sections::PublicArticles': typeof PublicArticles;
    'knowledge-hub/overview/sections/public-articles': typeof PublicArticles;
  }
}
