/* import __COLOCATED_TEMPLATE__ from './modal-footer.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  onModalClose?: () => void;
  submitSettings: () => void;
  submitSettingsButtonText: string;
  submitSettingsDisabled: boolean;
  submitSettingsDisabledReason: string;
}

const ModalFooter = templateOnlyComponent<Args>();
export default ModalFooter;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Conversational::Settings::ModalFooter': typeof ModalFooter;
  }
}
