/* import __COLOCATED_TEMPLATE__ from './source-card.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';
import { type SourceCardConfig } from '../all-sources-banner';

interface Signature {
  Element: HTMLDivElement;
  Args: { config: SourceCardConfig };
}

const SourceCard = templateOnlyComponent<Signature>();
export default SourceCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::SourceCard': typeof SourceCard;
    'knowledge-hub/overview/source-card': typeof SourceCard;
  }
}
