/* import __COLOCATED_TEMPLATE__ from './learn-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

export default class LearnButton extends Component {
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked activeVideoId?: string;

  get helpArticles() {
    if (this.appService.app.isStandaloneApp) {
      return [
        {
          text: this.intl.t('knowledge-hub.new-banner.actions.sync-articles-from-zendesk'),
          icon: 'article',
          onSelectItem: () => {
            window.Intercom('showArticle', 10617194); // https://fin.ai/help/en/articles/10617194-sync-from-your-zendesk-help-center
            this.trackDropdownClick('sync-articles-from-zendesk');
          },
        },
        {
          text: this.intl.t('knowledge-hub.new-banner.actions.sync-manage-websites'),
          icon: 'article',
          onSelectItem: () => {
            window.Intercom('showArticle', 10617480); // https://fin.ai/help/en/articles/10617480-sync-and-manage-websites
            this.trackDropdownClick('sync-manage-websites');
          },
        },
        {
          text: this.intl.t('knowledge-hub.new-banner.actions.optimizing-content-for-fin'),
          icon: 'article',
          onSelectItem: () => {
            window.Intercom('showArticle', 10625127); // https://fin.ai/help/en/articles/10625127-optimizing-content-for-fin
            this.trackDropdownClick('optimizing-content-for-fin');
          },
        },
        {
          text: this.intl.t('knowledge-hub.new-banner.actions.content-from-conversations'),
          icon: 'article',
          onSelectItem: () => {
            window.Intercom('showArticle', 10727368);
            this.trackDropdownClick('content-from-conversations');
          },
        },
      ];
    }

    return [
      {
        text: this.intl.t('knowledge-hub.overview.learn-dropdown.knowledge-explained'),
        icon: 'article',
        onSelectItem: () => {
          window.Intercom('showArticle', 9357912);
          this.trackDropdownClick('knowledge-explained');
        },
      },
      {
        text: this.intl.t('knowledge-hub.overview.learn-dropdown.help-center-explained'),
        icon: 'article',
        onSelectItem: () => {
          window.Intercom('showArticle', 56640);
          this.trackDropdownClick('help-center-explained');
        },
      },
      {
        text: this.intl.t('knowledge-hub.overview.learn-dropdown.optimizing-content-for-fin'),
        icon: 'article',
        onSelectItem: () => {
          window.Intercom('showArticle', 7860255);
          this.trackDropdownClick('optimizing-content-for-fin');
        },
      },
      {
        text: this.intl.t('knowledge-hub.overview.learn-dropdown.intro-to-knowledge-management'),
        icon: 'video',
        onSelectItem: () => {
          this.setActiveVideoId('vw0v77a3jn');
          this.trackDropdownClick('intro-to-knowledge-management');
        },
      },
      {
        text: this.intl.t('knowledge-hub.overview.learn-dropdown.how-the-knowledge-hub-works'),
        icon: 'video',
        onSelectItem: () => {
          this.setActiveVideoId('caromk1jsx');
          this.trackDropdownClick('how-the-knowledge-hub-works');
        },
      },
    ];
  }

  @action
  setActiveVideoId(videoId: string) {
    this.activeVideoId = videoId;
  }

  @action trackDropdownClick(name: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: name,
      place: 'learn-dropdown',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::LearnButton': typeof LearnButton;
    'knowledge-hub/overview/learn-button': typeof LearnButton;
  }
}
