/* import __COLOCATED_TEMPLATE__ from './knowledge-integration.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type IntlService from 'ember-intl/services/intl';
import type { SourceType } from 'embercom/models/import-service/import-source';
import { SyncBehaviorType } from 'embercom/models/import-service/import-source';
import type ImportSource from 'embercom/models/import-service/import-source';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';

interface Args {
  modal: any;
  title?: string;
  onModalClose: () => void;
  connectionType: string;
  sourceType: SourceType;
}

const CONNECTION_MAP: Record<string, string> = {
  'salesforce-src': 'salesforce',
};

interface DropdownItem {
  id: string;
  label: string;
  text: string;
}

export default class KnowledgeIntegrationSyncModal extends Component<Args> {
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare intl: IntlService;
  @service declare appService: { app: $TSFixMe };
  @service declare intercomEventService: any;

  @tracked importSource: ImportSource | undefined;
  @tracked syncBehavior: SyncBehaviorType = SyncBehaviorType.Sync;
  @tracked isConnecting = false;
  @tracked showFieldMapper = false;
  @tracked groupList: { items: DropdownItem[] }[] = [];
  @tracked mappingComplete = false;
  @tracked intercomFields: string[] = [];
  @tracked fieldMap: any = {};
  @tracked fieldMapperComponent: any;
  @tracked metadata: any;
  @tracked isMappingComplete = true;

  get connectionType(): string {
    return CONNECTION_MAP[this.args.connectionType];
  }

  get connectionIcon() {
    switch (this.connectionType) {
      case 'salesforce':
        return 'salesforce';
      default:
        return undefined;
    }
  }

  get connectionImageUrl() {
    return assetUrl(`/assets/images/knowledge-hub/${this.connectionType}-connection@2x.png`);
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  @action
  async initiateConnection() {
    if (this.isConnecting) {
      return;
    }

    this.isConnecting = true;
    try {
      await this.createConnection();
    } catch (error) {
      this.isConnecting = false;
      throw error;
    }
  }

  @action
  async createConnection() {
    let importSourceParams = {
      sourceType: this.args.sourceType,
    };
    this.importSource = await this.knowledgeHubService.createImportSource(importSourceParams);

    if (this.appService.app.canUseFieldMapperForImportSource) {
      this.showFieldMapper = true;
      this.isMappingComplete = false;
      await this.initiateFieldMapper();
    }

    if (this.isMappingComplete) {
      await this.startImport();
    }
  }

  @action
  async startImport() {
    if (!this.importSource) {
      throw new Error('Import source is not defined');
    }

    await this.knowledgeHubService.startImport(this.importSource.id, this.syncBehavior);

    if (!this.appService.app.canUseFieldMapperForImportSource) {
      this.args.onModalClose();
    }

    let notificationMessage =
      this.syncBehavior === SyncBehaviorType.Sync
        ? this.intl.t(`knowledge-hub.sync-modal.${this.connectionType}.sync-started`)
        : this.intl.t(`knowledge-hub.sync-modal.${this.connectionType}.import-started`);

    this.knowledgeHubService.notifySourceStateChangeConfirmation(
      notificationMessage,
      this.importSource.id,
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'import-content',
      object: `import-content.${this.connectionType}`,
      section: `knowledge-hub.new-content.${this.connectionType}`,
      place: 'knowledge-hub.new-content',
    });
  }

  @action
  async initiateFieldMapper() {
    if (!this.importSource) {
      throw new Error('Import source is not defined');
    }

    this.metadata = this.importSource.metadata;
    this.intercomFields = this.importSource.entityMetadata;

    this.showFieldMapper = true;
    this.isConnecting = false;

    this.groupList = [
      {
        items: this.metadata.map((field: any) => ({
          id: field.name,
          label: field.name,
          text: field.name,
        })),
      },
    ];
  }

  @action
  onFieldMapUpdate(newFieldMap: any) {
    this.fieldMap = newFieldMap;

    let requiredFields = this.importSource?.entityMetadata[0].required || [];

    this.mappingComplete = requiredFields.every((field: string) =>
      Object.keys(newFieldMap).includes(field),
    );
  }

  @action
  async completeMapping() {
    if (!this.importSource) {
      return;
    }
    await this.knowledgeHubService.updateImportSourceWithParam(this.importSource.id, {
      mappings: this.fieldMap,
    });

    this.args.onModalClose();
    this.isMappingComplete = true;
    this.startImport();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::KnowledgeIntegration': typeof KnowledgeIntegrationSyncModal;
    'knowledge-hub/setup-modal/sync-modal/knowledge-integration': typeof KnowledgeIntegrationSyncModal;
  }
}
