/* import __COLOCATED_TEMPLATE__ from './sync-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type IntlService from 'embercom/services/intl';
import { SyncSteps } from 'embercom/components/knowledge-hub/filterable-list';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  closeModal: () => void;
  setSyncStep: (step: SyncSteps) => void;
}

enum ImportSourceType {
  Confluence = 'confluence',
  Guru = 'guru',
  None = 'none',
  Notion = 'notion',
  Salesforce = 'salesforce_knowledge',
  Box = 'box',
  Document360 = 'document360',
}

export default class SyncModal extends Component<Args> {
  @service declare intl: IntlService;
  @tracked selectedSource: ImportSourceType = ImportSourceType.None;
  @service declare permissionsService: $TSFixMe;

  get connectButtonDisabled() {
    return this.selectedSource === ImportSourceType.None;
  }

  @action
  async connectToSource() {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
    } catch (error) {
      this.args.closeModal();
      return;
    }

    let sourceToStepMap = {
      [ImportSourceType.Confluence]: SyncSteps.ConfluenceSync,
      [ImportSourceType.Guru]: SyncSteps.GuruSync,
      [ImportSourceType.Notion]: SyncSteps.NotionSync,
      [ImportSourceType.Salesforce]: SyncSteps.SalesforceSync,
      [ImportSourceType.Box]: SyncSteps.BoxSync,
      [ImportSourceType.Document360]: SyncSteps.Document360Sync,
      [ImportSourceType.None]: undefined,
    };

    let step = sourceToStepMap[this.selectedSource];
    if (step) {
      this.args.setSyncStep(step);
    }
  }

  @action
  selectSource(source: string) {
    let step = this.convertSourceToType(source);
    this.selectedSource = step;
  }

  private convertSourceToType(source: string) {
    let isSourceTypeValid = Object.values(ImportSourceType).includes(source as ImportSourceType);
    if (isSourceTypeValid) {
      return source as ImportSourceType;
    }
    return ImportSourceType.None;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::FilterableList::SyncModal': typeof SyncModal;
    'knowledge-hub/setup-modal/sync-modal': typeof SyncModal;
  }
}
