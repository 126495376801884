/* import __COLOCATED_TEMPLATE__ from './notion.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { taskFor } from 'ember-concurrency-ts';
import type ImportSource from 'embercom/models/import-service/import-source';
import type IntlService from 'embercom/services/intl';
import { type SyncSteps } from 'embercom/components/knowledge-hub/filterable-list';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { SourceType, SyncBehaviorType } from 'embercom/models/import-service/import-source';
import type Store from '@ember-data/store';

interface Args {
  modal: any;
  title?: string;
  onNavigationBack?: () => void;
  onNavigationNext?: (viewId?: string) => void;
  showBackButton?: boolean;
  onModalClose: () => void;
  setSyncStep: (step: SyncSteps) => void;
}

export default class NotionSyncModal extends Component<Args> {
  @service declare appService: any;
  @service declare store: Store;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare openCenteredWindowService: any;
  @service declare intl: IntlService;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;

  @tracked importSource: ImportSource | undefined;
  @tracked syncBehavior: SyncBehaviorType = SyncBehaviorType.Sync;

  get isLoadingOauth() {
    return taskFor(this.startOauthFlow).isRunning;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    taskFor(this.startOauthFlow).perform();
  }

  get importSourceId() {
    return this.importSource?.id;
  }

  get syncDisabled() {
    return !this.importSourceId;
  }

  @action async startSync() {
    if (!this.importSource) {
      return;
    }

    this.knowledgeHubService.startImport(this.importSource.id, this.syncBehavior);
    this.args.onModalClose();

    let notificationMessage =
      this.syncBehavior === SyncBehaviorType.Sync
        ? this.intl.t('knowledge-hub.sync-modal.notion.sync-started')
        : this.intl.t('knowledge-hub.sync-modal.notion.import-started');
    this.knowledgeHubService.notifySourceStateChangeConfirmation(
      notificationMessage,
      this.importSource.id,
    );
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'import-content',
      object: `import-content.notion`,
      section: 'knowledge-hub.new-content.notion',
      place: 'knowledge-hub.new-content',
    });
  }

  @action async cancelSync() {
    if (this.importSource) {
      await this.importSource.remove({ notify: false });
    }
    this.args.onModalClose();
  }

  @task({ drop: true })
  *startOauthFlow(): TaskGenerator<void> {
    this.importSource = yield this.knowledgeHubService.createImportSource({
      sourceType: SourceType.Notion,
    });

    if (!this.importSource) {
      return;
    }

    let url = this.knowledgeHubService.getOAuthURL(this.importSource.id);

    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    let window = this.openCenteredWindowService.setup(url, 800, 1280, 'Import');
    if (window) {
      window.focus();
      yield this.waitForWindowClose(window);
    }

    yield this.importSource.reload();
    if (!this.importSource.hasCredentials) {
      this.args.onModalClose();
      yield this.importSource.remove({ notify: false });
    }
  }

  private waitForWindowClose(window: any): Promise<void> {
    return new Promise((resolve) => {
      let checkInterval = setInterval(() => {
        if (window.closed) {
          clearInterval(checkInterval);
          resolve();
        }
      }, 200);
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::SetupModal::SyncModal::Notion': typeof NotionSyncModal;
    'knowledge-hub/setup-modal/sync-modal/notion': typeof NotionSyncModal;
  }
}
