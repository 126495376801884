/* import __COLOCATED_TEMPLATE__ from './manage-conversations-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from 'tracked-built-ins';
import { inject as service } from '@ember/service';
import type CopilotApi from 'embercom/services/copilot-api';
import type IntlService from 'ember-intl/services/intl';
import { localCopy } from 'tracked-toolbox';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import type Attribute from 'embercom/models/attribute';

export interface Args {
  isOpen: boolean;
  onClose: () => void;
  allAdmins: { id: number; name: string; role?: string }[];
  enabledAdminIds: number[];
  useExcerpts: boolean;
  conversationAttributes?: { attributes: { identifier: string; type: string }[]; type: string };
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
  Blocks: {
    default: [];
  };
}

export default class ManageConversationsDrawer extends Component<Signature> {
  @service attributeService: any;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare copilotApi: CopilotApi;
  @service declare notificationsService: any;
  @service declare knowledgeHubService: KnowledgeHubService;

  @tracked selectedAdmins: number[] = this.args.enabledAdminIds;
  @tracked selectedAttributes: Attribute[] = [];
  @tracked selectedMatchingType = this.args.conversationAttributes?.type ?? 'or';
  @localCopy('args.useExcerpts') declare useExcerpts: boolean;

  ALL_ADMINS_ID = -1;
  @tracked hasEdited = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    if (this.args.enabledAdminIds.length === 0) {
      this.selectedAdmins = this.args.allAdmins.map((admin) => admin.id);
    }

    this.attributeService.filteredCopilotConversationAttributes.forEach((attribute: Attribute) => {
      if (
        this.args.conversationAttributes?.attributes.some(
          (conversationAttribute) => conversationAttribute.identifier === attribute.identifier,
        )
      ) {
        this.selectedAttributes.push(attribute);
      }
    });
  }

  @action close() {
    this.args.onClose();
  }

  @action onSelectAttribute(attributeValue: Attribute['identifier']) {
    this.hasEdited = true;
    let selectedAttribute = this.attributeService.filteredCopilotConversationAttributes.find(
      (attribute: Attribute) => attribute.identifier === attributeValue,
    );

    this.selectedAttributes = [...this.selectedAttributes, selectedAttribute];
  }

  @action onRemoveAttribute(attribute: Attribute) {
    this.hasEdited = true;
    this.selectedAttributes = this.selectedAttributes.filter(({ identifier }) => {
      return identifier !== attribute.identifier;
    });
    if (this.selectedAttributes.length <= 1) {
      this.selectedMatchingType = 'or';
    }
  }

  @action onUpdateSelectedMatchingType(type: string) {
    this.hasEdited = true;
    this.selectedMatchingType = type;
  }

  get dropdownAttributes(): Attribute[] {
    return this.attributeService.filteredCopilotConversationAttributes.filter(
      (attribute: Attribute) =>
        !this.selectedAttributes.some(
          (selectedAttribute) => selectedAttribute.identifier === attribute.identifier,
        ),
    );
  }

  get attributesPayload() {
    if (this.selectedAttributes.length === 0) {
      return null;
    }

    let attributes = this.selectedAttributes.map((attribute) => ({
      identifier: attribute.identifier,
      type: attribute.type,
    }));

    return {
      attributes,
      type: this.selectedMatchingType,
    };
  }

  get isSaveButtonDisabled(): boolean {
    return (this.selectedAdmins.length === 0 && !this.allAdminsEnabled) || !this.hasEdited;
  }

  @action async updateTeammates() {
    try {
      await this.knowledgeHubService.ensurePermissions();
    } catch (e) {
      return;
    }

    try {
      let trusted_ids = this.allAdminsEnabled
        ? []
        : this.selectedAdmins.filter((admin) => admin !== this.ALL_ADMINS_ID);
      await this.copilotApi.updateSettings({
        trustedAdminIds: trusted_ids,
        usePastConversations: this.useExcerpts,
        attributesPayload: this.attributesPayload,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'knowledge-hub.overview.sections.syncs.modals.manage-conversations.toast-success',
        ),
      );
      this.args.onClose();
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t(
          'knowledge-hub.overview.sections.syncs.modals.manage-conversations.toast-error',
        ),
      );
      throw new Error('Failed to set trusted admin ids', e.message);
    }
  }

  get listedAdmins(): { text: string; description?: string; value: number; isSelected: boolean }[] {
    return [
      {
        text: this.intl.t(
          'knowledge-hub.overview.sections.syncs.modals.manage-conversations.all-teammates',
        ),
        value: this.ALL_ADMINS_ID,
        isSelected: this.allAdminsEnabled,
      },
      ...this.args.allAdmins.map((admin) => ({
        text: admin.name,
        description: admin.role,
        value: admin.id,
        isSelected: this.selectedAdmins.includes(admin.id),
      })),
    ];
  }

  @action onSelectItem(id: number) {
    this.hasEdited = true;
    if (id === this.ALL_ADMINS_ID) {
      this.toggleAllAdmins();
      return;
    }

    if (this.selectedAdmins.includes(id)) {
      this.selectedAdmins = this.selectedAdmins.filter((adminId) => adminId !== id);
    } else {
      this.selectedAdmins = [...this.selectedAdmins, id];
    }
  }

  private toggleAllAdmins() {
    if (this.allAdminsEnabled) {
      this.selectedAdmins = [];
    } else {
      this.selectedAdmins = this.args.allAdmins.map((admin) => admin.id);
    }
  }

  get showError() {
    return this.isSaveButtonDisabled && this.hasEdited;
  }

  get selectedAdminLabel() {
    if (!this.useExcerpts) {
      return this.intl.t(
        'knowledge-hub.overview.sections.syncs.modals.manage-conversations.no-teammates',
      );
    }
    if (this.allAdminsEnabled) {
      return this.intl.t(
        'knowledge-hub.overview.sections.syncs.modals.manage-conversations.all-teammates',
      );
    } else {
      return this.intl.t(
        'knowledge-hub.overview.sections.syncs.modals.manage-conversations.num-teammates',
        {
          count: this.selectedAdmins.length,
        },
      );
    }
  }
  get allAdminsEnabled() {
    return this.args.allAdmins.every((admin) => this.selectedAdmins.includes(admin.id));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::ManageConversationsDrawer::ManageConversationsDrawer': typeof ManageConversationsDrawer;
    'knowledge-hub/manage-conversations-drawer/manage-conversations-drawer': typeof ManageConversationsDrawer;
  }
}
