/* import __COLOCATED_TEMPLATE__ from './share-with-teammate-modal.hbs'; */
/* RESPONSIBLE TEAM: team-purchase */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Product from 'embercom/models/product';
import type Router from '@ember/routing/router-service';

interface Args {
  product: Product;
}

export default class ShareWithTeammateModalComponent extends Component<Args> {
  @service declare appService: any;
  @service declare router: Router;

  get url(): string {
    let product_route = this.router.urlFor(
      'apps.app.intershop.add-ons.add-on.pricing',
      this.args.product.slug,
    );
    return `${window.location.protocol}//${window.location.host}${product_route}`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Intershop::Modals::ShareWithTeammateModal': typeof ShareWithTeammateModalComponent;
    'intershop/modals/share-with-teammate-modal': typeof ShareWithTeammateModalComponent;
  }
}
