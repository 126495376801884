/* import __COLOCATED_TEMPLATE__ from './connect-source-dropdown-option.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-foundations */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  item: { icon: InterfaceIconName; text: string };
}

const ConnectSourceDropdownOption = templateOnlyComponent<Signature>();
export default ConnectSourceDropdownOption;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'KnowledgeHub::Overview::Shared::ConnectSourceDropdownOption': typeof ConnectSourceDropdownOption;
    'knowledge-hub/overview/shared/connect-source-dropdown-option': typeof ConnectSourceDropdownOption;
  }
}
